export const CORONA_DEMO_ORDER = 'CORONA_DEMO_ORDER';
export const CORONA_DEMO_ERROR = 'CORONA_DEMO_ERROR';
export const CORONA_DEMO_LOADING = 'CORONA_DEMO_LOADING';
export const CORONA_DEMO_SUCCESS = 'CORONA_DEMO_SUCCESS';
export const CORONA_DEMO_SELECT = 'CORONA_DEMO_SELECT';
export const CORONA_DEMO_CLEAR = 'CORONA_DEMO_CLEAR';
export const CORONA_DEMO_ELIGIBILITY = 'CORONA_DEMO_ELIGIBILITY';
export const CORONA_DEMO_VERIFY = 'CORONA_DEMO_VERIFY';
export const CORONA_DEMO_CONFIRM = 'CORONA_DEMO_CONFIRM';
export const CORONA_DEMO_RESEND = 'CORONA_DEMO_RESEND';
export const CORONA_DEMO_RESET = 'CORONA_DEMO_RESET';
export const CORONA_DEMO_PRODUCT_CODE = 'CR00MPMOTCL001';
