import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

const NotEligible = ({ t }) => {
  return (
    <div className="maya-ple">
      <Helmet>
        <title>V-Ray for Blender beta</title>
        <meta name="description" content="V-Ray for Blender beta" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('V-Ray for Blender beta')}</h3>
      </div>
      <div className="content free-trials">
        <div className="content role-form">
          <br />
          <br />
          <div className="centered" style={{ lineHeight: '26px' }}>
            <br />
            {t(
              'You already have an active Blender beta license. Your license will be valid throughout the beta period.',
            )}{' '}
            <br />
            {t('If you have any questions, ')}
            <a target="_blank" rel="noreferrer" href="https://forums.chaos.com/forum/v-ray-for-blender-beta">
              {t('visit our forum')}
            </a>
            .
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NotEligible);
