export const BETA_BLENDER_SELECT = 'BETA_BLENDER_SELECT';
export const BETA_BLENDER_CLEAR = 'BETA_BLENDER_CLEAR';
export const BETA_BLENDER_CREATE = 'BETA_BLENDER_CREATE';
export const BETA_BLENDER_ERROR = 'BETA_BLENDER_ERROR';
export const BETA_BLENDER_SET_LOADING = 'BETA_BLENDER_SET_LOADING';
export const BETA_BLENDER_SET_STEP = 'BETA_BLENDER_SET_STEP';

export const BetaFields = [
  { label: 'Animation', value: 'Animation' },
  { label: 'Architecture', value: 'Architecture' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'Interior Design', value: 'Interior Design' },
  { label: 'Product Design', value: 'Product Design' },
  { label: 'VFX', value: 'VFX' },
  { label: 'Other', value: 'Other' },
];

export const BlenderRenderEngines = [
  { label: 'Cycles', value: 'Cycles' },
  { label: 'Redshift', value: 'Redshift' },
  { label: 'Octane', value: 'Octane' },
  { label: 'RenderMan', value: 'RenderMan' },
  { label: 'Other', value: 'Other' },
];
