import React from 'react';
import { Trans } from 'react-i18next';

export default () => (
  <p className="centered">
    <Trans
      i18nKey="By continuing, I accept the Privacy Policy, Terms of Use and EULA"
      components={{
        privacy: (
          <a href={`${window.WEBSITE_URL}/privacy`} target="_blank" rel="noreferrer">
            Data privacy
          </a>
        ),
        terms: (
          <a href={`${window.WEBSITE_URL}/terms`} target="_blank" rel="noreferrer">
            Terms & Conditions
          </a>
        ),
        eula: (
          <a href={`${window.WEBSITE_URL}/eula`} target="_blank" rel="noreferrer">
            EULA
          </a>
        ),
      }}
    />
  </p>
);
