import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

const WelcomeBack = ({ t }) => {
  return (
    <div className="content welcome-back">
      <h3>{t('Welcome back!')}</h3>
      <p className="subtitle">
        {t(
          "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.",
        )}
      </p>
      <div className="actions">
        <button
          type="button"
          className="button secondary"
          onClick={() => {
            window.location.href = window.MY_CHAOS_URL;
          }}
        >
          {t('Manage your licenses')}
        </button>
        <button
          type="button"
          className="button"
          onClick={() => {
            window.location.href = window.PRICING_URL;
          }}
        >
          {t('Discover plans & pricing')}
        </button>
      </div>
      <div className="divider" />
      <p className="need-help">
        <strong>{t('Need a hand?')}</strong>
        <div>
          <Trans
            i18nKey="personalized-assistance"
            components={{
              assistance: (
                <a target="_blank" href={`${window.WEBSITE_URL}/contact-us/sales`} rel="noreferrer">
                  personalized assistance
                </a>
              ),
            }}
          />
        </div>
      </p>
    </div>
  );
};

export default withTranslation()(WelcomeBack);
