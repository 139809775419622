import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const SuccessEnvision = ({ t }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="maya-ple">
      <Helmet>
        <title>Envision Beta</title>
        <meta name="description" content="Envision Beta" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('Welcome to the Envision, beta.')}</h3>
      </div>
      <div className="content free-trials maya-ple envision">
        <br />
        <div className="content">
          <div>
            {t(
              'Your beta license is now active. You can start testing all the new features and improvements we’ve introduced. Your license will be valid throughout the beta testing period. ',
            )}
          </div>

          <br />
          <div className="centered">
            <button
              type="button"
              className="button"
              style={{ width: 'auto' }}
              onClick={() => {
                window.location.href = `${window.DOWNLOADS_URL}/products/EC01NOSPBCL001`;
              }}
            >
              {t('Download beta')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SuccessEnvision);
