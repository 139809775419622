import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BETA_SELECT } from '../constants';
import { Industries, Roles } from '../../FreeTrial/constants';

const EnvisionForm = ({ selected, onFormChange, setCanContinue, t }) => {
  const { industry, visualization_tools } = selected;
  const { name, role, additional_name, additional_role } = industry || {};

  useEffect(() => {
    if (selected.industry && name && role && visualization_tools) {
      if ((name === 'Other' && !additional_name) || (role === 'Other' && !additional_role)) {
        setCanContinue(false);
        return;
      }
      setCanContinue(true);
      return;
    }

    setCanContinue(false);
  }, [selected]);

  return (
    <>
      <p>{t('Field of practice')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) =>
              onFormChange({
                industry: {
                  ...industry,
                  name: e.target.value,
                  additional_name: '',
                },
              })
            }
            value={name}
          >
            <option value="">{t('Please select')}</option>
            {Industries.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {name === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your industry')} *</label>
              <input
                type="text"
                required
                defaultValue={additional_name}
                onChange={(e) =>
                  onFormChange({
                    industry: {
                      ...industry,
                      additional_name: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>{t('Role')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) =>
              onFormChange({
                industry: {
                  ...industry,
                  role: e.target.value,
                  additional_role: '',
                },
              })
            }
            defaultValue={role}
          >
            <option value="">{t('Please select')}</option>
            {Roles.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {role === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your role')} *</label>
              <input
                type="text"
                required
                value={additional_role}
                onChange={(e) =>
                  onFormChange({
                    industry: {
                      ...industry,
                      additional_role: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <br />
        </>
      )}
      <>
        <div className="form-field-wrapper">
          <div className="item item-country">
            <label htmlFor="number">
              {t('Please provide a list of any visualization tools that you are currently using')} *
            </label>
            <input
              type="text"
              required
              value={visualization_tools}
              onChange={(e) =>
                onFormChange({
                  visualization_tools: e.target.value,
                })
              }
            />
          </div>
        </div>
        <br />
      </>
    </>
  );
};

export default connect(
  (state) => ({
    selected: state.beta.selected,
    location: state.auth.Location,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: BETA_SELECT, data }),
  }),
)(withTranslation()(EnvisionForm));
