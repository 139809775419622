import React from 'react';
import Spinner from 'react-spinkit';

const Loading = () => {
  return (
    <div className="loading">
      <Spinner fadeIn="none" />
      <br />
      <span>Loading...</span>
    </div>
  );
};

export default Loading;
