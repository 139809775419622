import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { MAYA_PLE_RENEW, MAYA_PLE_SELECT, MAYA_PLE_SET_DETAILS_CONFIRMED, MAYA_PLE_SET_STEP } from '../constants';

import FirstRenewal from './FirstRenewal';
import SubsequentRenewal from './SubsequentRenewal';
import InitialApplication from '../InitialApplication';
import Privacy from '../Privacy';

const renderComponent = (formType, props) => {
  if (formType === 'firstRenewal') {
    return <FirstRenewal {...props} />;
  }

  return <SubsequentRenewal {...props} />;
};

const Renewal = ({ formType, selected, detailsConfirmed, onFormChange, onSubmit, onBack, setDetailsConfirmed, t }) => {
  return (
    <>
      {detailsConfirmed ? (
        renderComponent(formType, { selected, onFormChange, onSubmit })
      ) : (
        <InitialApplication shouldConfirm />
      )}
      {detailsConfirmed && (
        <>
          <br />
          <div className="centered">
            <a
              className="back-button"
              onClick={() => {
                setDetailsConfirmed(false);
                onBack();
              }}
            >
              &lt; {t('Back')}
            </a>
          </div>
        </>
      )}
      <br />
      <Privacy />
    </>
  );
};

export default connect(
  (state) => ({
    selected: state.mayaPle.selected,
    detailsConfirmed: state.mayaPle.detailsConfirmed,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: MAYA_PLE_SELECT, data }),
    onSubmit: () => dispatch({ type: MAYA_PLE_RENEW }),
    onBack: () => dispatch({ type: MAYA_PLE_SET_STEP, step: 0 }),
    setDetailsConfirmed: (confirmed) => dispatch({ type: MAYA_PLE_SET_DETAILS_CONFIRMED, confirmed }),
  }),
)(withTranslation()(Renewal));
