export const MAYA_PLE_SELECT = 'MAYA_PLE_SELECT';
export const MAYA_PLE_CLEAR = 'MAYA_PLE_CLEAR';
export const MAYA_PLE_SET_FORM_TYPE = 'MAYA_PLE_SET_FORM_TYPE';
export const MAYA_PLE_CREATE = 'MAYA_PLE_CREATE';
export const MAYA_PLE_RENEW = 'MAYA_PLE_RENEW';
export const MAYA_PLE_ERROR = 'MAYA_PLE_ERROR';
export const MAYA_PLE_SET_LOADING = 'MAYA_PLE_SET_LOADING';
export const MAYA_PLE_GET_DETAILS = 'MAYA_PLE_GET_DETAILS';
export const MAYA_PLE_SET_DETAILS_CONFIRMED = 'MAYA_PLE_SET_DETAILS_CONFIRMED';
export const MAYA_PLE_SET_STEP = 'MAYA_PLE_SET_STEP';
