import React from 'react';
import { Helmet } from 'react-helmet';

import '../../styles/corona_demo.sass';

const CoronaDemoLayout = ({ children }) => {
  if (window.location.hostname !== window.domains.corona) {
    return null;
  }
  return (
    <div className="corona-demo">
      <Helmet>
        <title>Corona Demo</title>
        <meta name="description" content="Corona Demo" />
        <link href="/corona-favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
      </Helmet>
      <div
        className="column image-cover"
        data-copyright="© Nikita Voropaev | behance.net/voropaevni57be"
        style={{ backgroundImage: 'url(/images/corona/corona-cover.jpg)' }}
      >
        <a href="https://corona-renderer.com" className="logo corona">
          <img src="/images/corona/corona-logo.svg" alt="corona" />
        </a>
      </div>
      <div className="column content">
        <div className="top-wrapper">
          <div className="mobile-logo">
            <a href="https://corona-renderer.com" className="logo corona">
              <img src="/images/corona/corona-logo.svg" alt="corona" />
            </a>
          </div>
        </div>
        <div className="wrapper-shrink">{children}</div>
      </div>
    </div>
  );
};

export default CoronaDemoLayout;
