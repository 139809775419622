export const LANGUAGE_COOKIE = '_cgloc';

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  return parts.length === 2 ? parts.pop().split(';').shift() : '';
};

export const setCookie = (name, value, domain, expires) => {
  let cookie = `${name}=${value};domain=${domain};path=/`;
  if (expires) {
    cookie += `;expires=${expires}`;
  }
  document.cookie = cookie;
};

export function getMainDomain(hostname) {
  const parts = hostname.split('.');

  // If the hostname has more than two parts, it's a subdomain
  if (parts.length > 2) {
    return parts.slice(-2).join('.');
  }

  return hostname;
}
