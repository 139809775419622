import { reducer as routing } from 'redux-saga-first-router';
import { combineReducers } from 'redux';

import { auth } from './app/App/reducers';
import freeTrial from './app/FreeTrial/reducers';
import coronaDemo from './app/Demo/reducers';
import mayaPle from './app/MayaPle/reducers';
import beta from './app/Beta/reducers';
import betaBlender from './app/BetaBlender/reducers';

export default combineReducers({
  auth,
  routing,
  freeTrial,
  coronaDemo,
  mayaPle,
  beta,
  betaBlender,
});
