const initialState = {
  loading: true,
  error: null,
  UserID: null,
};

export function auth(state = initialState, payload) {
  switch (payload.type) {
    case 'GET_SESSION#START':
      return { ...state, loading: true };
    case 'GET_SESSION#COMPLETE':
      return { ...state, loading: false, ...payload.data };
    default:
      return state;
  }
}
