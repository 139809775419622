import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Occupation from './Occupation';
import { OCCUPATION_COMPONENTS } from './occupations';
import { BUNDLE_TRIALS } from '../../FreeTrial/constants';

const Form = ({ routing, onBack, onContinueFinish, onOccupationSelect, buttonLabel, t, ...otherProps }) => {
  const selectedOccupation = otherProps.selected.using_as;
  const [canContinue, setCanContinue] = useState(false);

  const { product } = routing.params;
  const showBackButton = BUNDLE_TRIALS.includes(product) && selectedOccupation;

  return (
    <div className="content role-form">
      <Occupation selectedOccupation={selectedOccupation} onOccupationSelect={onOccupationSelect} />

      {selectedOccupation && (
        <>
          <br />
          <br />
          {OCCUPATION_COMPONENTS[selectedOccupation]({ canContinue, setCanContinue, ...otherProps })}
        </>
      )}

      {selectedOccupation && (
        <>
          <br />
          <div className="centered">
            <button type="button" className="button" onClick={onContinueFinish} disabled={!canContinue}>
              {buttonLabel}
            </button>
          </div>
        </>
      )}
      {showBackButton && (
        <>
          <br />
          <div className="centered">
            <a className="back-button" onClick={() => onBack(routing.params)}>
              &lt; {t('Back')}
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default connect((state) => ({
  routing: state.routing,
}))(withTranslation()(Form));
