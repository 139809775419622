import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { PlannedUseDuration, RenewalReasons, UseFrequency } from '../InitialApplication/constants';

const requiredFields = ['primary_renewal_reason', 'frequency_of_usage', 'dcc_usage', 'renderer_usage', 'planned_usage'];

const FirstRenewal = ({ selected, onFormChange, onSubmit, t }) => {
  const {
    primary_renewal_reason,
    other_primary_renewal_reason,
    frequency_of_usage,
    dcc_usage,
    other_dcc_usage,
    renderer_usage,
    other_renderer_usage,
    feedback,
    planned_usage,
  } = selected;
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    const areRequiredFieldsFilled = requiredFields.every((field) => selected[field]);
    if (areRequiredFieldsFilled) {
      if (
        (primary_renewal_reason === 'Other' && !other_primary_renewal_reason) ||
        (dcc_usage === 'Yes' && !other_dcc_usage) ||
        (renderer_usage === 'Yes' && !other_renderer_usage)
      ) {
        setCanContinue(false);
        return;
      }
      setCanContinue(true);
      return;
    }

    setCanContinue(false);
  }, [selected]);

  return (
    <>
      <p>{t('What’s the primary reason for renewing your V-Ray PLE license?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) => onFormChange({ primary_renewal_reason: e.target.value })}
            value={primary_renewal_reason}
          >
            <option value="">{t('Please select')}</option>
            {RenewalReasons.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {primary_renewal_reason === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label>{t('Please specify')} *</label>
              <input
                type="text"
                required
                defaultValue={other_primary_renewal_reason}
                onChange={(e) => onFormChange({ other_primary_renewal_reason: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>{t('How often do you typically use V-Ray PLE?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ frequency_of_usage: e.target.value })} value={frequency_of_usage}>
            <option value="">{t('Please select')}</option>
            {UseFrequency.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <p>{t('Do you use any other DCCs apart from Autodesk Maya?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ dcc_usage: e.target.value })} value={dcc_usage}>
            <option value="">{t('Please select')}</option>
            {[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ].map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {dcc_usage === 'Yes' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label>{t('Please specify which other DCCs you’re using.')} *</label>
              <input
                type="text"
                required
                defaultValue={other_dcc_usage}
                onChange={(e) => onFormChange({ other_dcc_usage: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>{t('Do you use any other renderers?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ renderer_usage: e.target.value })} value={renderer_usage}>
            <option value="">{t('Please select')}</option>
            {[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ].map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {renderer_usage === 'Yes' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label>{t('Please specify which other renderer(s) you’re using.')} *</label>
              <input
                type="text"
                required
                defaultValue={other_renderer_usage}
                onChange={(e) => onFormChange({ other_renderer_usage: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>{t('How long do you plan to use V-Ray PLE?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ planned_usage: e.target.value })} value={planned_usage}>
            <option value="">{t('Please select')}</option>
            {PlannedUseDuration.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <p>
        {t('Please share any feedback about your experience with V-Ray PLE and the available learning resources.')}{' '}
        <span className="optional-label">{t('(optional)')}</span>
      </p>
      <div className="form-field-wrapper">
        <div className="item item-organization">
          <input
            type="text"
            required
            value={feedback || ''}
            onChange={(e) => onFormChange({ feedback: e.target.value })}
          />
        </div>
      </div>
      <br />
      <br />
      <div className="centered">
        <button type="button" className="button" onClick={onSubmit} disabled={!canContinue}>
          {t('Submit')}
        </button>
      </div>
    </>
  );
};

export default withTranslation()(FirstRenewal);
