import React from 'react';
import { humanizeError } from './demoSagas';

const errors = Object.freeze({
  'demo-exists': <div className="error">You already have activated a demo on this account.</div>,
  'failed-activation': (
    <div className="error minor">
      Your trial has been activated, but a connection to your local licensing server could not be established. Please
      see this page for more information:{' '}
      <a className="blue-anchor" href="https://support.chaos.com/hc/en-us/articles/5147044693393">
        https://support.chaos.com/hc/en-us/articles/5147044693393
      </a>
      .
    </div>
  ),
  'invalid-code': <div className="error">Invalid code.</div>,
  generic: (
    <div className="error">
      <div>Something went wrong and your license was not activated.</div>
      <div>It&apos;s probably our fault, so please try again.</div>
      <div>
        If you need help,{' '}
        <a className="blue-anchor" href={window.SUPPORT_URL}>
          contact us.
        </a>
      </div>
    </div>
  ),
});

const Error = ({ message }) => {
  const err = humanizeError(message);
  return <div>{errors[err]}</div>;
};

export default Error;
