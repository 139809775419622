import {
  FREE_TRIAL_CLEAR_ROLE,
  FREE_TRIAL_CLEAR_ERROR,
  FREE_TRIAL_ERROR,
  FREE_TRIAL_SET_LOADING,
  FREE_TRIAL_SELECT,
  FREE_TRIAL_SET_STEP,
  GET_STATUS_COMPLETE,
  FREE_TRIAL_SET_ORDER_PLACED,
  FREE_TRIAL_SET_IMPACT_ELIGIBLE,
} from './constants';

const initialState = {
  is_eligible: null,
  sms_verification_required: null,
  verification_status: null,
  orderPlaced: false,
  progressBar: {
    step: null,
  },
  selected: {},
  loading: false,
  error: null,
};

export default function freeTrial(state = initialState, payload) {
  switch (payload.type) {
    case GET_STATUS_COMPLETE:
      return { ...state, ...payload.data, loading: false };
    case FREE_TRIAL_SET_LOADING:
      return { ...state, loading: payload.loading };
    case FREE_TRIAL_ERROR:
      return { ...state, error: payload.error, loading: false };
    case FREE_TRIAL_CLEAR_ERROR:
      return { ...state, error: null };
    case FREE_TRIAL_SET_STEP:
      return {
        ...state,
        progressBar: {
          ...state.progressBar,
          step: payload.step,
        },
        loading: false,
        error: null,
      };
    case FREE_TRIAL_SELECT:
      return {
        ...state,
        selected: { ...state.selected, ...payload.data },
      };
    case FREE_TRIAL_CLEAR_ROLE:
      return {
        ...state,
        selected: Object.fromEntries(
          Object.entries(state.selected).filter(([key]) => key === 'tool' || key === 'platform'),
        ),
      };
    case FREE_TRIAL_SET_ORDER_PLACED:
      return {
        ...state,
        orderPlaced: payload.isPlaced,
      };
    case FREE_TRIAL_SET_IMPACT_ELIGIBLE: {
      return {
        ...state,
        isImpactEligible: payload.isImpactEligible,
      };
    }
    default:
      return state;
  }
}
