import { call, put, select, takeLatest, fork } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

import Api from '../Utils/Api';
import { BETA_BLENDER_CREATE, BETA_BLENDER_ERROR, BETA_BLENDER_SET_LOADING, BETA_BLENDER_SET_STEP } from './constants';
import { PRODUCTS } from '../FreeTrial/constants';
import { placeOrder } from '../Shared/sagas';

export function* navigateBetaBlender() {
  yield fork(watchCreate);

  yield put({ type: BETA_BLENDER_SET_LOADING, loading: true });

  const routing = yield select((state) => state.routing);
  let result = {};
  try {
    result = yield call(Api.fetch, `/trial/eligible/${PRODUCTS.blender.code}`);
  } catch (error) {
    yield put({ type: BETA_BLENDER_ERROR, error });
  }
  yield put({ type: BETA_BLENDER_SET_LOADING, loading: false });

  if (!result.is_eligible) {
    yield put(
      navigate('BETA_BLENDER_WELCOME_BACK', {
        lang: routing.params.lang,
        product: routing.params.product,
      }),
    );
  }
}

function* watchCreate() {
  yield takeLatest(BETA_BLENDER_CREATE, onCreate);
}

function* onCreate() {
  yield put({ type: BETA_BLENDER_SET_LOADING, loading: true });
  const routing = yield select((state) => state.routing);
  const { selected } = yield select((state) => state.betaBlender);

  try {
    let subscriptionNumber = '';
    yield call(placeOrder, {
      additionalFields: {
        code: PRODUCTS.blender.code,
      },
      successCallback: (response) => {
        subscriptionNumber = response.subscriptionNumber;
      },
      errorCallback: () => {},
    });

    const body = {
      ...selected,
      code: PRODUCTS.blender.code,
      subscription_number: subscriptionNumber,
      product_of_interest: 'V-Ray',
      platform_of_interest: 'Blender',
    };

    yield call(Api.fetch, '/trial/info', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      parseJSON: false,
      body: JSON.stringify(body),
    });

    yield put(
      navigate('BETA_BLENDER_SUCCESS', {
        lang: routing.params.lang,
        product: routing.params.product,
      }),
    );
  } catch (error) {
    yield put({ type: BETA_BLENDER_ERROR, error });
    window.scrollTo(0, 0);
  }
  yield put({ type: BETA_BLENDER_SET_STEP, step: 2 });
  yield put({ type: BETA_BLENDER_SET_LOADING, loading: false });
}
