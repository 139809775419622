import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { CustomStepper } from '../FreeTrial/Wiz';

const Success = ({ email, t }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="maya-ple">
      <Helmet>
        <title>Enscape 4.1 Preview</title>
        <meta name="description" content="Enscape 4.1 Preview" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('Enscape 4.1 Preview')}</h3>
      </div>
      <div className="content free-trials maya-ple">
        <CustomStepper steps={Array(3).fill()} step={2} />
        <br />
        <br />
        <h4>{t('Thank you')}</h4>
        <br />
        <div className="content">
          <div>
            <strong>{t('Your license is now active')}</strong>.
          </div>
          <div>
            {t("We've sent an email with further information to")} <b>{email}</b>.
          </div>
          <br />
          <div className="centered">
            <button
              type="button"
              className="button"
              style={{ width: 'auto' }}
              onClick={() => {
                window.location.href = `${window.DOWNLOADS_URL}/products/EN00MPMOTCL001`;
              }}
            >
              {t('Download')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({ email: state.auth.Email }))(withTranslation()(Success));
