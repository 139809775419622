import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { MAYA_PLE_SELECT } from '../constants';
import { EduFields } from './constants';
import { countries } from '../../Utils/Countries';

const requiredFields = ['field_of_teaching', 'institution_name'];

const Educator = ({ selected, location, onFormChange, setCanContinue, t }) => {
  const { field_of_teaching, other_field_of_teaching, institution_name, phone_number, dialCode, phoneNumber } =
    selected;

  useEffect(() => {
    const areRequiredFieldsFilled = requiredFields.every((field) => {
      if (field === 'field_of_teaching' && field_of_teaching === 'Other') {
        return other_field_of_teaching;
      }

      return selected[field];
    });
    if (areRequiredFieldsFilled) {
      setCanContinue(true);
      return;
    }

    setCanContinue(false);
  }, [selected]);

  useEffect(() => {
    const locationDialCode = countries.find((country) => country.code === location).dial_code;
    if (phone_number && phone_number.includes(locationDialCode)) {
      const phone = phone_number.replace(locationDialCode, '');
      onFormChange({ phoneNumber: phone });
    }
    onFormChange({ dialCode: locationDialCode });
  }, []);

  // eslint-disable-next-line camelcase
  const countryDialCodes = countries.map(({ dial_code }) => dial_code);
  const uniqueCountryDialCodes = [...new Set(countryDialCodes)];

  return (
    <>
      <p>{t('What is your field of teaching?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ field_of_teaching: e.target.value })} value={field_of_teaching}>
            <option value="">{t('Please select')}</option>
            {EduFields.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {field_of_teaching === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your field of teaching')} *</label>
              <input
                type="text"
                required
                defaultValue={other_field_of_teaching}
                onChange={(e) => onFormChange({ other_field_of_teaching: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>{t('Which university/training center do you teach at?')}</p>
      <div className="form-field-wrapper">
        <div className="item item-organization">
          <input
            type="text"
            required
            value={institution_name || ''}
            onChange={(e) => onFormChange({ institution_name: e.target.value })}
          />
        </div>
      </div>
      <br />
      <p>
        {t('Your phone number')} <span className="optional-label">{t('(optional)')}</span>
      </p>
      <div className="form-field-wrapper phone">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ dialCode: e.target.value })} value={dialCode}>
            <option value="" />
            {uniqueCountryDialCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>
        </div>
        <div className="item item-organization">
          <input
            type="number"
            value={phoneNumber || ''}
            onChange={(e) => onFormChange({ phoneNumber: e.target.value })}
          />
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    selected: state.mayaPle.selected,
    location: state.auth.Location,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: MAYA_PLE_SELECT, data }),
  }),
)(withTranslation()(Educator));
