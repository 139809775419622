import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Loading from '../App/Loading';

import InitialApplication from './InitialApplication';
import Renewal from './Renewal';
import { CustomStepper } from '../FreeTrial/Wiz';

const getTitle = (formType, step) => {
  if (formType !== 'initial') {
    if (step === 0) {
      return 'Please confirm that your details are up-to-date.';
    }
    return 'Tell us more about your experience with PLE.';
  }

  return 'Customize your first steps into V-Ray for Maya.';
};

const MayaPle = ({ loading, formType, step, error, t }) => {
  if (loading || !formType) {
    return <Loading />;
  }

  return (
    <div className="maya-ple">
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('V-Ray Personal Learning Edition (PLE) for Maya')}</h3>
      </div>
      <div className="content free-trials">
        <CustomStepper steps={Array(3).fill()} step={step} />
        <br />
        <br />
        <h4>{t(getTitle(formType, step))}</h4>
        <br />
        {error && <div className="error">{error.message}</div>}
        <br />
        <div className="content role-form">
          {formType === 'initial' ? <InitialApplication /> : <Renewal formType={formType} />}
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  loading: state.mayaPle.loading,
  formType: state.mayaPle.formType,
  step: state.mayaPle.step,
  error: state.mayaPle.error,
}))(withTranslation()(MayaPle));
