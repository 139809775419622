import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

const requiredFields = ['feedback'];

const SubsequentRenewal = ({ selected, onFormChange, onSubmit, t }) => {
  const [canContinue, setCanContinue] = useState(false);
  const { feedback, share_your_work } = selected;

  useEffect(() => {
    const areRequiredFieldsFilled = requiredFields.every((field) => selected[field]);
    if (areRequiredFieldsFilled) {
      setCanContinue(true);
      return;
    }

    setCanContinue(false);
  }, [selected]);

  return (
    <>
      <p>{t('Please share any feedback about your experience with V-Ray PLE and the available learning resources.')}</p>
      <div className="form-field-wrapper">
        <div className="item item-organization">
          <input
            type="text"
            required
            value={feedback || ''}
            onChange={(e) => onFormChange({ feedback: e.target.value })}
          />
        </div>
      </div>
      <br />
      <p>
        {t('Share your work done with V-Ray PLE with us.')} <span className="optional-label">{t('(optional)')}</span>
      </p>
      <div className="form-field-wrapper">
        <div className="item item-organization">
          <input
            type="text"
            required
            value={share_your_work || ''}
            onChange={(e) => onFormChange({ share_your_work: e.target.value })}
          />
        </div>
      </div>
      <br />
      <br />
      <div className="centered">
        <button type="button" className="button" onClick={onSubmit} disabled={!canContinue}>
          {t('Submit')}
        </button>
      </div>
    </>
  );
};

export default withTranslation()(SubsequentRenewal);
