import React from 'react';

import Business from './Business';
import Individual from './Individual';
import Student from './Student';

export const OCCUPATION_COMPONENTS = {
  business: (props) => <Business {...props} />,
  individual: (props) => <Individual {...props} />,
  student: (props) => <Student {...props} />,
};
