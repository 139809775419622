import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Form from '../Shared/Form';
import { CORONA_DEMO_SELECT, CORONA_DEMO_ORDER, CORONA_DEMO_CLEAR } from './constants';

const CoronaDemoForm = ({ t, ...props }) => {
  return <Form {...props} buttonLabel={t('Start your trial')} />;
};

export default connect(
  (state) => ({
    selected: state.coronaDemo.selected,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: CORONA_DEMO_SELECT, data }),
    onOccupationSelect: (occupation) => dispatch({ type: CORONA_DEMO_SELECT, data: { using_as: occupation } }),
    onBack: () => dispatch({ type: CORONA_DEMO_CLEAR }),
    onContinueFinish: () => dispatch({ type: CORONA_DEMO_ORDER }),
  }),
)(withTranslation()(CoronaDemoForm));
