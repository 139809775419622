import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Loading from '../App/Loading';
import Error from './Error';
import Form from './Form';
import Verify from './Verify';
import VerifyConfirm from './VerifyConfirm';

const Demo = ({ auth, coronaDemo, t }) => {
  if (!auth.UserGUID || !coronaDemo.eligibility) {
    return null;
  }

  const verificationIsRequired =
    coronaDemo.eligibility.is_eligible &&
    coronaDemo.eligibility.sms_verification_required &&
    coronaDemo.eligibility.verification_status !== 'finished';
  const alreadyActivated = !coronaDemo.eligibility.is_eligible && !coronaDemo.eligibility.sms_verification_required;

  if (coronaDemo.loading) {
    return <Loading />;
  }

  return (
    <div className="content">
      {coronaDemo.error && <Error message={coronaDemo.error} />}
      {coronaDemo.formIsVisible && !coronaDemo.error && !verificationIsRequired && !alreadyActivated && (
        <>
          <h4>{t('Your trial is a step away')}</h4>
          <br />
          <br />
          <Form />
        </>
      )}
      {verificationIsRequired && (
        <div className="free-trials">
          <h4>{t('SMS Verification')}</h4>
          <br />
          <br />
          <br />
          {coronaDemo.eligibility.verification_status === 'initial' ? <Verify /> : <VerifyConfirm />}
        </div>
      )}
      <br />
      <br />
    </div>
  );
};

export default connect((state) => ({
  auth: state.auth,
  coronaDemo: state.coronaDemo,
}))(withTranslation()(Demo));
