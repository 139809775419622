import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { OCCUPATION_COMPONENTS } from './occupations';

const Occupation = ({ occupations, selectedOccupation, onOccupationSelect, t }) => {
  const occupationsValues = occupations || Object.keys(OCCUPATION_COMPONENTS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content occupation">
      <p>{t('What is your current occupation?')}</p>
      <br />
      <div className="form-field-wrapper box-buttons">
        {occupationsValues.map((occupation) => {
          const isSelected = occupation === selectedOccupation;
          const className = isSelected ? 'box-button selected' : 'box-button';
          return (
            <div
              key={occupation}
              className={className}
              onClick={() => {
                onOccupationSelect(occupation);
              }}
            >
              <img alt={occupation} src={`/images/trial/occupation/${occupation}.svg`} />
              {t(occupation)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation()(Occupation);
