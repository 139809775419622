import { call, put, cancel } from 'redux-saga/effects';

const defaultFetchOptions = {
  host: window.API_URL,
  parseJSON: true,
  onUnauthorized: 'redirect',
  on4xx: 'throw',
  on5xx: 'API_ERROR',
  credentials: 'include',
  redirect: 'manual',
};

const unauthorizedCodes = [401];

function* responseError(response) {
  const error = {
    HTTPStatus: response.status,
    message: '',
  };

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') === 0) {
    const responseBody = yield call(() => response.json());
    return {
      ...error,
      ...(responseBody.error ? responseBody.error : responseBody),
    };
  }

  const responseText = yield call(() => response.text());
  return {
    ...error,
    message: ['An unexpected error occurred.', response.status, responseText].join(' '),
  };
}

function* errorHandler(action, response, retryHandler) {
  if (action === 'redirect') {
    window.location = window.location.href;
    yield cancel();

    return false;
  }

  let error;
  if (response) {
    error = yield call(responseError, response);
  } else if (navigator.onLine === false) {
    error = {
      message: 'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.',
    };
  } else {
    error = {
      message: 'We are having technical difficulties. Please try later.',
    };
  }

  switch (action) {
    case 'throw':
      throw error;
    case 'ignore':
      return error;
    default:
      yield put({ type: action, data: { error, retryHandler } });
  }

  return false;
}

function* responseHandler(response, { parseJSON, onUnauthorized, on4xx, on5xx, retryHandler }) {
  if (unauthorizedCodes.indexOf(response.status) >= 0) {
    return yield call(errorHandler, onUnauthorized, response, retryHandler);
  }

  if (/^4[0-9]{2}$/.exec(response.status)) {
    return yield call(errorHandler, on4xx, response, retryHandler);
  }

  if (/^5[0-9]{2}$/.exec(response.status)) {
    return yield call(errorHandler, on5xx, response, retryHandler);
  }

  if (!parseJSON) {
    return response;
  }

  try {
    return yield call(() => response.json());
  } catch (err) {
    return yield call(errorHandler, on5xx, null, retryHandler);
  }
}

const api = {
  *fetch(path, options = {}) {
    const { host, parseJSON, onUnauthorized, on4xx, on5xx, retryHandler, ...request } = {
      ...defaultFetchOptions,
      ...options,
    };
    const address = (host || window.API_URL) + path;

    let response = null;
    try {
      response = yield call(fetch, address, request);
    } catch (err) {
      return yield call(errorHandler, on5xx, null, retryHandler);
    }

    return yield call(responseHandler, response, {
      address,
      parseJSON,
      onUnauthorized,
      on4xx,
      on5xx,
      retryHandler,
    });
  },
};

export default api;
