import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Footer, Header } from '@chaos/layout';

import '../../styles/globals.css';
import '@chaos/layout/dist/index.css';

import '../../styles/free_trials.sass';
import '../../styles/maya_ple.sass';

const MayaPleLayout = ({ auth, onLangChange, routing, children }) => {
  const language = routing.params.lang || 'en';
  const langSegment = `/${language}`;
  const { i18n } = useTranslation();
  const user = { fullName: `${auth.FirstName} ${auth.LastName}`, email: auth.Email };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [routing]);

  if (!auth.UserID) {
    return null;
  }

  if (window.location.hostname !== window.domains.chaos && window.location.hostname !== window.domains.ple) {
    return null;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>Chaos Free Trial</title>
          <meta name="description" content="Chaos Free Trial" />
          <link href="/chaos-favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        </Helmet>
        <Header
          url={`${window.WEBSITE_URL}${langSegment}/api/header`}
          returnUrl={encodeURIComponent(window.location.href)}
          user={user}
          country={auth.Location}
          currentLanguage={language}
          languages={[
            { code: 'EN', label: 'English', link: '#' },
            { code: 'ES', label: 'Español', link: '#' },
            { code: 'PT', label: 'Português (Brasil)', link: '#' },
            { code: 'CN', label: '简体中文', link: '#' },
            { code: 'KR', label: '한국어', link: '#' },
            { code: 'JP', label: '日本語', link: '#' },
          ]}
          onChangeLanguage={onLangChange}
        />
        <div className="chaos-page-wrapper" style={{ minHeight: 300 }}>
          {children}
        </div>
      </div>
      <Footer url={`${window.WEBSITE_URL}${langSegment}/api/footer`} hidePreFooter />
    </>
  );
};

export default connect(
  (state) => ({
    auth: state.auth,
    routing: state.routing,
  }),
  (dispatch) => ({
    onLangChange: (lang) => dispatch({ type: 'LANG#CHANGE', lang: lang.toLowerCase() }),
  }),
)(MayaPleLayout);
