import { call } from 'redux-saga/effects';

import api from '../Utils/Api';

export function* placeOrder({ additionalFields, successCallback, errorCallback }) {
  try {
    const response = yield call(api.fetch, '/trial/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      onUnauthorized: 'throw',
      parseJSON: true,
      body: JSON.stringify({
        origin: window.THEME,
        product_type: '',
        code: '',
        ...additionalFields,
      }),
    });

    yield call(successCallback, response);
  } catch (error) {
    yield call(errorCallback, error);
    window.scrollTo(0, 0);
  }
}
