import {
  MAYA_PLE_SELECT,
  MAYA_PLE_CLEAR,
  MAYA_PLE_SET_FORM_TYPE,
  MAYA_PLE_SET_LOADING,
  MAYA_PLE_SET_DETAILS_CONFIRMED,
  MAYA_PLE_ERROR,
  MAYA_PLE_SET_STEP,
} from './constants';

const initialState = {
  error: null,
  loading: false,
  step: 0,
  formType: null,
  selected: null,
  detailsConfirmed: false,
};

export default function mayaPle(state = initialState, payload) {
  switch (payload.type) {
    case MAYA_PLE_SET_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case MAYA_PLE_SELECT:
      return {
        ...state,
        selected: { ...state.selected, ...payload.data },
      };
    case MAYA_PLE_CLEAR:
      return {
        ...state,
        selected: {},
      };
    case MAYA_PLE_SET_FORM_TYPE:
      return {
        ...state,
        formType: payload.formType,
      };
    case MAYA_PLE_SET_DETAILS_CONFIRMED:
      return {
        ...state,
        detailsConfirmed: payload.confirmed,
        step: 1,
      };
    case MAYA_PLE_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case MAYA_PLE_SET_STEP:
      return {
        ...state,
        step: payload.step,
      };
    default:
      return state;
  }
}
