import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Industries, Roles, StudyFields } from '../../FreeTrial/constants';
import { countries } from '../../Utils/Countries';

const FullForm = ({
  selected,
  location,
  individual,
  student,
  business,
  onFormChange,
  canContinue,
  setCanContinue,
  t,
}) => {
  const { industry, company, field_of_study, university, phoneNumber, dialCode, number_of_specialists } = selected;

  const { name, role, additional_name, additional_role } = industry || {};

  useEffect(() => {
    const locationDialCode = countries.find((country) => country.code === location).dial_code;
    onFormChange({ dialCode: locationDialCode });
  }, []);

  useEffect(() => {
    const canUserContinue = checkCanContinue(
      role,
      additional_role,
      name,
      additional_name,
      company,
      number_of_specialists,
      field_of_study,
      university,
      {
        individual,
        student,
        business,
      },
    );

    if (canUserContinue !== canContinue) {
      setCanContinue(canUserContinue);
    }
  });

  const specialistsNumberChoices = {
    0: "It's just me",
    '1-3': '1-3',
    '4+': '4+',
  };
  // eslint-disable-next-line camelcase
  const countryDialCodes = countries.map(({ dial_code }) => dial_code);
  const uniqueCountryDialCodes = [...new Set(countryDialCodes)];

  return (
    <>
      {(business || individual) && (
        <>
          <p>{t('Field of practice')}</p>
          <div className="form-field-wrapper ">
            <div className="styled-select">
              <select
                id="code"
                onChange={(e) =>
                  onFormChange({
                    ...selected,
                    industry: {
                      ...industry,
                      name: e.target.value,
                      additional_name: '',
                    },
                  })
                }
                value={name}
              >
                <option value="">{t('Please select')}</option>
                {Industries.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {t(label)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          {name && name === 'Other' && (
            <>
              <div className="form-field-wrapper">
                <div className="item item-country">
                  <label htmlFor="number">{t('Fill in your industry')} *</label>
                  <input
                    type="text"
                    required
                    defaultValue={additional_name}
                    onChange={(e) =>
                      onFormChange({
                        ...selected,
                        industry: {
                          ...industry,
                          additional_name: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <br />
            </>
          )}
          <p>{t('Role')}</p>
          <div className="form-field-wrapper">
            <div className="styled-select">
              <select
                id="code"
                onChange={(e) =>
                  onFormChange({
                    ...selected,
                    industry: {
                      ...industry,
                      role: e.target.value,
                      additional_role: '',
                    },
                  })
                }
                value={role}
              >
                <option value="">{t('Please select')}</option>
                {Roles.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {t(label)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          {role && role === 'Other' && (
            <>
              <div className="form-field-wrapper">
                <div className="item item-country">
                  <label htmlFor="number">{t('Fill in your role')} *</label>
                  <input
                    type="text"
                    required
                    defaultValue={additional_role}
                    onChange={(e) =>
                      onFormChange({
                        ...selected,
                        industry: {
                          ...industry,
                          additional_role: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <br />
            </>
          )}
        </>
      )}
      {business && (
        <>
          <p>{t('Organization name')}</p>
          <div className="form-field-wrapper">
            <div className="item item-organization">
              <input
                type="text"
                required
                value={company || ''}
                onChange={(e) => onFormChange({ company: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      {student && (
        <>
          <p>{t('Field of study')}</p>
          <div className="form-field-wrapper ">
            <div className="styled-select">
              <select
                id="code"
                onChange={(e) => onFormChange({ field_of_study: e.target.value })}
                value={field_of_study}
              >
                <option value="">{t('Please select')}</option>
                {StudyFields.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {t(label)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <p>{t('University name')}</p>
          <div className="form-field-wrapper">
            <div className="item item-organization">
              <input
                type="text"
                required
                value={university || ''}
                onChange={(e) => onFormChange({ university: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      {business && (
        <>
          <p>{t('Do you have colleagues interested in licenses as well?')}</p>
          <div className="form-field-wrapper box-buttons">
            {Object.keys(specialistsNumberChoices).map((choice) => {
              const isSelected = choice === number_of_specialists;
              const className = isSelected ? 'box-button selected' : 'box-button';
              return (
                <div key={choice} className={className} onClick={() => onFormChange({ number_of_specialists: choice })}>
                  {t(specialistsNumberChoices[choice])}
                </div>
              );
            })}
          </div>
          <br />
        </>
      )}
      <p>
        {t('Your phone number')} <span className="optional-label">{t('(optional)')}</span>
      </p>
      <div className="form-field-wrapper phone">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ dialCode: e.target.value })} value={dialCode}>
            <option value="" />
            {uniqueCountryDialCodes
              .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
              .map((code) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
          </select>
        </div>
        <div className="item item-organization">
          <input
            type="number"
            value={phoneNumber || ''}
            onChange={(e) => onFormChange({ phoneNumber: e.target.value })}
          />
        </div>
      </div>
    </>
  );
};

const checkCanContinue = (
  role,
  additional_role,
  name,
  additional_name,
  company,
  number_of_specialists,
  field_of_study,
  university,
  formTypes,
) => {
  const { business, individual, student } = formTypes;

  if (student && (!field_of_study || !university)) {
    return false;
  }

  if (business && (!company || !number_of_specialists)) {
    return false;
  }

  if (individual || business) {
    if (!role || !name) {
      return false;
    }
    if (role === 'Other' && !additional_role) {
      return false;
    }
    if (name === 'Other' && !additional_name) {
      return false;
    }
  }

  return true;
};

export default connect((state) => ({
  routing: state.routing,
  location: state.auth.Location,
}))(withTranslation()(FullForm));
