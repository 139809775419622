export const translations = {
  en: {
    translation: {
      lang: 'en',
      'Your trial is a step away': 'Your trial is a step away',
      business: 'Business',
      student: 'Student',
      educator: 'Educator',
      individual: 'Individual',
      'Verify your account.': 'Verify your account.',
      'Tool of choice': 'Tool of choice',
      'Which best describes your role?': 'Which best describes your role?',
      'What is your role?': 'What is your role?',
      Administrator: 'Administrator',
      'Application Engineer': 'Application Engineer',
      Architect: 'Architect',
      'BIM Specialist': 'BIM Specialist',
      'Business Owner': 'Business Owner',
      'C-Suite | Managing Partner': 'C-Suite | Managing Partner',
      'Construction Specialist': 'Construction Specialist',
      Consultant: 'Consultant',
      'Designer - Interior': 'Designer - Interior',
      'Designer - Product': 'Designer - Product',
      'Director / Principal / Team Leader': 'Director / Principal / Team Leader',
      'Educator / Professor / Teacher': 'Educator / Professor / Teacher',
      'Procurement / Admin': 'Procurement / Admin',
      Engineer: 'Engineer',
      'Operations Manager': 'Operations Manager',
      'Marketing Specialist': 'Marketing Specialist',
      'Project Manager': 'Project Manager',
      Student: 'Student',
      'Visualization Specialist | 3D Artist': 'Visualization Specialist | 3D Artist',
      Other: 'Other',
      'What industry are you in?': 'What industry are you in?',
      'What is your field of practice?': 'What is your field of practice?',
      "What is your organization's name?": "What is your organization's name?",
      'What is your field of study?': 'What is your field of study?',
      "What is your university's name?": "What is your university's name?",
      'Media & Entertainment': 'Media & Entertainment',
      Architecture: 'Architecture',
      Automotive: 'Automotive',
      'Building operations and management': 'Building operations and management',
      Construction: 'Construction',
      Consulting: 'Consulting',
      'Education/Academia': 'Education/Academia',
      Engineering: 'Engineering',
      'Interior design': 'Interior design',
      'Landscape architecture': 'Landscape architecture',
      'Product design': 'Product design',
      'Real estate & Development': 'Real estate & Development',
      'Urban planning': 'Urban planning',
      'Company name': 'Company name',
      'Educational institution': 'Educational institution',
      'What is your primary tool of choice?': 'What is your primary tool of choice?',
      'What operating system will you use?': 'What operating system will you use?',
      'Host platform': 'Host platform',
      'What is your current occupation?': 'What is your current occupation?',
      professional: 'Professional',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        'Your Chaos Cloud credits are now active and will be available for the next 30 days.',
      "We've sent an email with further information to": "We've sent an email with further information to",
      "You'll need to download all the products you'd like to try individually.":
        "You'll need to download all the products you'd like to try individually.",
      'Find out': 'Find out',
      'how to install': 'how to install',
      'This helps us confirm your identity and secure your account':
        'This helps us confirm your identity and secure your account',
      "We've just sent a text message with a 6-digit verification code to":
        "We've just sent a text message with a 6-digit verification code to",
      'Verification code': 'Verification code',
      'Verify account': 'Verify account',
      "Didn't get the code?": "Didn't get the code?",
      'Note that this can take a few minutes': 'Note that this can take a few minutes',
      'Resend the code': 'Resend the code',
      'Please enter your phone number': 'Please enter your phone number',
      'We will send you a one-time verification code': 'We will send you a one-time verification code',
      'Country code': 'Country (Region) code',
      Number: 'Number',
      'Get verification code': 'Get verification code',
      'Thanks for your interest in V-Ray and Chaos. It seems that you’ve already received a free trial or your license includes access to all Chaos products.':
        'Thanks for your interest in V-Ray and Chaos. It seems that you’ve already received a free trial or your license includes access to all Chaos products.',
      'You can check your active and expired licenses in your account.':
        'You can check your active and expired licenses in <1>your account</1>.',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        'Want to buy a new license? Visit our <1>pricing page</1> and find what you need.',
      'Need help? Visit our help center for quick answers.':
        'Need help? Visit our <1>help center</1> for quick answers.',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        'You have already tested Chaos Cloud. You can add more credits to your account via <2>our website</2> or your <5>preferred reseller</5>.',
      'Please select': 'Please select',
      'We’d love to have you back!': 'We’d love to have you back!',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.',
      'If you have any questions, visit our forum.': 'If you have any questions, <1>visit our forum</1>.',
      'Thank you for joining the {{betaProduct}}.': 'Thank you for joining the {{betaProduct}}.',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        "Your beta license is now active. You can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to",
      'Download beta': 'Download beta',
      'Start rendering': 'Start rendering',
      // Chaos Vantage
      'Download product': 'Download product',
      'Experience real-time like never before.': 'Experience real-time like never before.',
      'Your product is now ready for download.': 'Your product is now ready for download.',
      'This promotional license is free of charge and valid until December 31, 2022.':
        'This promotional license is free of charge and valid until <strong>December 31, 2022</strong>.',
      'If you’d like to download more than one license, please contact our friendly support team.':
        'If you’d like to download more than one license, please <2>contact our friendly support team</2>.',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray Personal Learning Edition (PLE) for Maya',
      'Fill in your field of teaching': 'Fill in your field of teaching',
      'Which university/training center do you teach at?': 'Which university/training center do you teach at?',
      'Your phone number': 'Your phone number',
      'Change occupation': 'Change occupation',
      'Fill in your industry': 'Fill in your industry',
      'Fill in your field of study': 'Fill in your field of study',
      'What is your university’s/training center’s name?': 'What is your university’s/training center’s name?',
      '(optional)': '(optional)',
      'How often do you typically use V-Ray PLE?': 'How often do you typically use V-Ray PLE?',
      'Do you use any other DCCs apart from Autodesk Maya?': 'Do you use any other DCCs apart from Autodesk Maya?',
      Yes: 'Yes',
      No: 'No',
      'Please specify which other DCCs you’re using.': 'Please specify which other DCCs you’re using.',
      'Do you use any other renderers?': 'Do you use any other renderers?',
      'Please specify which other renderer(s) you’re using.': 'Please specify which other renderer(s) you’re using.',
      'How long do you plan to use V-Ray PLE?': 'How long do you plan to use V-Ray PLE?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'Please share any feedback about your experience with V-Ray PLE and the available learning resources.',
      'Share your work done with V-Ray PLE with us.': 'Share your work done with V-Ray PLE with us.',
      Submit: 'Submit',
      'Please confirm that your details are up-to-date.': 'Please confirm that your details are up-to-date.',
      'Tell us more about your experience with PLE.': 'Tell us more about your experience with PLE.',
      'Customize your first steps into V-Ray for Maya.': 'Customize your first steps into V-Ray for Maya.',
      'You already have an active V-Ray PLE for Maya license.':
        'You already have an active V-Ray PLE for Maya license.',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.',
      'If it expires, you can request a new one by completing the form again.':
        'If it expires, you can request a new one by completing the form again.',
      'Follow the steps here': 'Follow the steps here',
      'Thank you': 'Thank you',
      'Your license is now active, and will be available for the':
        'Your license is now active, and will be available for the',
      'next 90 days': 'next 90 days',
      Download: 'Download',
      Animation: 'Animation',
      Advertising: 'Advertising',
      'Education / Academia': 'Education / Academia',
      Gaming: 'Gaming',
      'Product Design': 'Product Design',
      'Visualization Specialist / 3D Artist': 'Visualization Specialist / 3D Artist',
      'Technical Director': 'Technical Director',
      'CG / VFX Supervisor': 'CG / VFX Supervisor',
      'I didn’t have time to test it': 'I didn’t have time to test it',
      'I want to keep learning': 'I want to keep learning',
      'I have a personal/university project to complete': 'I have a personal/university project to complete',
      'I am attending a rendering class/course': 'I am attending a rendering class/course',
      'Every day': 'Every day',
      'Every week': 'Every week',
      'Once a month': 'Once a month',
      'Occasionally to complete a project/class': 'Occasionally to complete a project/class',
      'One month': 'One month',
      'Three months': 'Three months',
      'Six months': 'Six months',
      'As long as I need to learn V-Ray': 'As long as I need to learn V-Ray',
      'Not sure, just testing for now': 'Not sure, just testing for now',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        'By continuing, I accept the <privacy>Privacy Policy</privacy>, <terms>Terms of Use</terms>, and <eula>EULA</eula>',
      'What is your field of teaching?': 'What is your field of teaching?',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        'What’s the primary reason for renewing your V-Ray PLE license?',
      Confirm: 'Confirm',
      "Let's customize your trial experience.": "Let's customize your trial experience.",
      'Tell us a bit more about yourself.': 'Tell us a bit more about yourself.',
      'Field of practice': 'Field of practice',
      Role: 'Role',
      'Organization name': 'Organization name',
      'Do you have colleagues interested in licenses as well?':
        'Do you have colleagues interested in licenses as well?',
      "It's just me": "It's just me",
      "We're happy to have you onboard!": "We're happy to have you onboard!",
      'Now you can explore everything included in your free {{duration}}-day trial.':
        'Now you can explore everything included in your free {{duration}}-day trial.',
      'Download your trial': 'Download your trial',
      Continue: 'Continue',
      'Start your free trial': 'Start your free trial',
      Back: 'Back',
      'Welcome back!': 'Welcome back!',
      'Manage your licenses': 'Manage your licenses',
      'Discover plans & pricing': 'Discover plans & pricing',
      'Need a hand?': 'Need a hand?',
      'personalized-assistance': 'Our sales team is ready for <assistance>personalized assistance</assistance>.',
      'Your trial download is just moments away.': 'Your trial download is just moments away.',
      'Great to see your interest in Chaos products.': 'Great to see your interest in Chaos products.',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.",
      'Please enter your phone number. We will send you a one-time verification code':
        'Please enter your phone number. We will send you a one-time verification code',
      'Phone number': 'Phone number',
      'You will get exclusive updates and training materials': 'You will get exclusive updates and training materials',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  es: {
    translation: {
      lang: 'es',
      'Your trial is a step away': 'Su versión de prueba está a un paso',
      'Customize your trial experience.': 'Personalice su experiencia de prueba',
      'What is your current occupation?': '¿Cuál es su ocupación actual?',
      'What is your field of practice?': '¿Cuál es su campo de práctica?',
      'What is your role?': '¿Cuál es tu roll?',
      "What is your organization's name?": '¿Cuál es el nombre de su organización?',
      optional: 'оpcional',
      'What is your field of study?': '¿Cuál es tu campo de estudio?',
      "What is your university's name?": '¿Cuál es el nombre de su universidad?',
      'Welcome to Chaos!': '¡Bienvenidos a Chaos!',
      Administrator: 'Administrador',
      'Application Engineer': 'Ingeniero de aplicaciones',
      Architect: 'Arquitecto',
      'BIM Specialist': 'Especialista BIM',
      'Business Owner': 'Dueño del negocio',
      'C-Suite | Managing Partner': 'C-Suite | Socio Director',
      'Construction Specialist': 'Especialista en Construcción',
      Consultant: 'Consultor',
      'Designer - Interior': 'Diseñador - Interiores',
      'Designer - Product': 'Diseñador - Productos',
      'Director / Principal / Team Leader': 'Director / Gerente / Jefe de equipo',
      'Educator / Professor / Teacher': 'Educador / Profesor / Teacher',
      'Procurement / Admin': 'Adquisiciones / Administración',
      Engineer: 'Ingeniero',
      'Operations Manager': 'Gerente de operaciones',
      'Marketing Specialist': 'Especialista de Marketing',
      'Project Manager': 'Gerente de proyecto',
      Student: 'Estudiante',
      'Visualization Specialist | 3D Artist': 'Especialista en visualización | Artista 3D',
      'Media & Entertainment': 'Medios y Entretenimiento',
      Architecture: 'Arquitectura',
      Automotive: 'Automotriz',
      'Building operations and management': 'Operaciones y gestión de construcción   ',
      Construction: 'Construcción',
      Consulting: 'Consultoría',
      'Education/Academia': 'Educación/Academia',
      Engineering: 'Ingeniería',
      'Interior design': 'Diseño de interiores',
      'Landscape architecture': 'Arquitectura de Paisaje',
      'Product design': 'Diseño de productos',
      'Real estate & Development': 'Desarrollo inmobiliario',
      'Urban planning': 'Urbanismo',
      business: 'Comercial',
      student: 'Estudiante',
      educator: 'Educador',
      individual: 'Individual',
      'Verify your account.': 'Verifica tu cuenta',
      'Tool of choice': 'Herramienta de elección',
      'Which best describes your role?': '¿Cuál describe mejor su rol?',
      Other: 'Otro',
      'What industry are you in?': '¿En qué industria está?',
      'Education | Academia': 'Educación | Academia',
      'Company name': 'Nombre de la compañía',
      'Educational institution': 'Institución educativa',
      'What is your primary tool of choice?': '¿Cuál es su principal herramienta de elección?',
      'What operating system will you use?': '¿Qué sistema operativo utilizarás?',
      'Host platform': 'Plataforma principal',
      professional: 'Professional',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        'Sus créditos de Chaos Cloud ahora están activos y estarán disponibles durante los próximos 30 días.',
      "We've sent an email with further information to": 'Hemos enviado un correo electrónico con más información a',
      "You'll need to download all the products you'd like to try individually.":
        'Tendrá que descargar todos los productos que le gustaría probar individualmente.',
      'Find out': 'Descubrir',
      'how to install': 'Cómo instalar',
      'This helps us confirm your identity and secure your account':
        'Esto nos ayuda a confirmar su identidad y proteger su cuenta',
      "We've just sent a text message with a 6-digit verification code to":
        'Acabamos de enviar un mensaje de texto con un código de verificación de 6 dígitos a',
      'Verification code': 'Código de verificación',
      'Verify account': 'Verificar Cuenta',
      "Didn't get the code?": '¿No recibió el código?',
      'Note that this can take a few minutes': 'Tenga en cuenta que esto puede tardar unos minutos',
      'Resend the code': 'Reenviar el código',
      'Please enter your phone number': 'Por favor, introduzca su número de teléfono',
      'We will send you a one-time verification code': 'Le enviaremos un código de verificación único',
      'Country code': 'Código de país',
      Number: 'Número',
      'Get verification code': 'Obtener código de verificación',
      'Thanks for your interest in Chaos products. It seems that you already received a trial.':
        'Gracias por su interés en los productos Chaos. Parece que ya recibió una versión de prueba.',
      'You can check your active and expired licenses in your account.':
        'Puede consultar sus licencias activas y expiradas en <1>su cuenta</1>.',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        '¿Quiere comprar una nueva licencia? Visite nuestra <1>página de precios</1> y encuentre lo que necesita.',
      'Need help? Visit our help center for quick answers.':
        '¿Necesita ayuda? Visite nuestro <1>centro de ayuda</1> para respuestas rápidas.',
      'We’d love to have you back!': '¡Nos encantaría tenerle de vuelta!',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        'Ya ha probado Chaos Cloud. Puede agregar más créditos a su cuenta a través de <2>nuestra página web</2> o su <5>reseller preferido</5>.',
      'Please select': 'Por favor, seleccione',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        'Ya tienes licencia de {{betaProduct}}. Tu licencia será válida durante todo el período beta oficial.',
      'If you have any questions, visit our forum.': 'Si tienes alguna pregunta, <1>visita nuestro foro</1>.',
      'Thank you for joining the {{betaProduct}}.': 'Gracias por unirse a {{betaProduct}}.',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        'Tu licencia beta ahora está activa. Ahora, puedes comenzar a probar todas las nuevas funciones y mejoras que hemos agregado. Tu licencia será válida durante todo el período de prueba beta oficial. Hemos enviado un correo electrónico con más información a',
      'Download beta': 'Descargar beta',
      'Start rendering': 'Comenzar a renderizar',
      // Chaos Vantage
      'Download product': 'Descargar producto',
      'Experience real-time like never before.': 'Experimente el real-time como nunca antes.',
      'Your product is now ready for download.': 'Su producto está listo para ser descargado.',
      'This promotional license is free of charge and valid until December 31, 2022.':
        'Esta licencia promocional es gratuita y válida hasta el 21 de septiembre de 2022.',
      'If you’d like to download more than one license, please contact our friendly support team.':
        'Si desea descargar más de una licencia, por favor <2>contacte a nuestro amigable equipo de soporte</2>.',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray Personal Learning Edition (PLE) for Maya',
      'Fill in your field of teaching': 'Completa tu campo de enseñanza',
      'Which university/training center do you teach at?': '¿En qué universidad/centro de formación impartes clases?',
      'Your phone number': 'Tu número de teléfono',
      'Change occupation': 'Cambiar de ocupación',
      'Fill in your industry': 'Completa tu industria',
      'Fill in your field of study': 'Completa tu campo de estudio',
      'What is your university’s/training center’s name?': '¿Cómo se llama tu universidad/centro de formación?',
      '(optional)': '(оpcional)',
      'How often do you typically use V-Ray PLE?': '¿Con qué frecuencia sueles utilizar V-Ray PLE?',
      'Do you use any other DCCs apart from Autodesk Maya?': '¿Utilizas algún otro DCC aparte de Autodesk Maya?',
      Yes: 'Si',
      No: 'No',
      'Please specify which other DCCs you’re using.': 'Especifica qué otros DCC estás utilizando.',
      'Do you use any other renderers?': '¿Utilizas algún otro renderizador?',
      'Please specify which other renderer(s) you’re using.':
        'Por favor, especifica qué otros renderizadores estás utilizando.',
      'How long do you plan to use V-Ray PLE?': '¿Durante cuánto tiempo piensas utilizar V-Ray PLE?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'Comparte cualquier comentario sobre tu experiencia con V-Ray PLE y los recursos de aprendizaje disponibles.',
      'Share your work done with V-Ray PLE with us.': 'Comparte con nosotros tu trabajo hecho con V-Ray PLE.',
      Submit: 'Enviar',
      'Please confirm that your details are up-to-date.': 'Por favor confirma que tus datos estén actualizados.',
      'Tell us more about your experience with PLE.': 'Cuéntanos más sobre tu experiencia con PLE.',
      'Customize your first steps into V-Ray for Maya.': 'Personaliza tus primeros pasos en V-Ray para Maya.',
      'You already have an active V-Ray PLE for Maya license.': 'Ya tienes una licencia activa de V-Ray PLE para Maya.',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        'Tu licencia es válida por 90 días y puede renovarse dentro de los 7 días previos al vencimiento.',
      'If it expires, you can request a new one by completing the form again.':
        'Si caduca, puedes solicitar una nueva completando nuevamente el formulario.',
      'Follow the steps here': 'Sigue los pasos aquí',
      'Thank you': 'Gracias',
      'Your license is now active, and will be available for the':
        'Tu licencia ya está activa y estará disponible para.',
      'next 90 days': 'los siguientes 90 días',
      Download: 'Descargar',
      Animation: 'Animación',
      Advertising: 'Publicidad',
      'Education / Academia': 'Educación / Academia',
      Gaming: 'Videojuegos',
      'Product Design': 'Diseño de producto',
      'Visualization Specialist / 3D Artist': 'Especialista en Visualización / Artista 3D',
      'Technical Director': 'Director Técnico',
      'CG / VFX Supervisor': 'Supervisor CG / VFX',
      'I didn’t have time to test it': 'No tuve tiempo de probarlo',
      'I want to keep learning': 'Quiero seguir aprendiendo',
      'I have a personal/university project to complete': 'Tengo un proyecto personal/universitario que realizar',
      'I am attending a rendering class/course': 'Estoy asistiendo a una clase/curso de renderizado',
      'Every day': 'Todos los días',
      'Every week': 'Cada semana',
      'Once a month': 'Una vez por mes',
      'Occasionally to complete a project/class': 'Ocasionalmente para completar un proyecto/clase.',
      'One month': 'Un mes',
      'Three months': 'Tres meses',
      'Six months': 'Seis meses',
      'As long as I need to learn V-Ray': 'Mientras necesite aprender V-Ray',
      'Not sure, just testing for now': 'No estoy seguro, solo estoy probando por ahora',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        'Al continuar, acepto la <privacy>Política de Privacidad</privacy>, los <terms>Términos de Uso</terms>, y el <eula>EULA</eula>',
      'What is your field of teaching?': '¿Cuál es tu campo de enseñanza?',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        '¿Cuál es el motivo principal para renovar tu licencia V-Ray PLE?',
      Confirm: 'Confirmar',
      "Let's customize your trial experience.": 'Personalicemos tu experiencia de prueba.',
      'Tell us a bit more about yourself.': 'Cuéntanos un poco más sobre ti.',
      'Field of practice': 'Campo de práctica',
      Role: 'Rol',
      'Organization name': 'Nombre de la Organizaciónname',
      'Do you have colleagues interested in licenses as well?': '¿Tienes colegas interesados en las licencias también?',
      "It's just me": 'Sólo soy yo',
      "We're happy to have you onboard!": 'Estamos felices de tenerte a bordo.',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        'Ahora puedes explorar todo lo incluido en tu prueba gratuita de {{duration}} días.',
      'Download your trial': 'Descarga tu prueba',
      Continue: 'Continuar',
      'Start your free trial': 'Comienza tu prueba gratuita',
      Back: 'Atrás',
      'Welcome back!': '¡Bienvenido de nuevo!',
      'Manage your licenses': 'Administra tus licencias',
      'Discover plans & pricing': 'Descubre planes y precios',
      'Need a hand?': '¿Necesitas una mano?',
      'personalized-assistance':
        'Nuestro equipo de ventas está listo para brindarle <assistance>asistencia personalizada</assistance>.',
      'Your trial download is just moments away.': 'La descarga de tu prueba está a solo unos minutos de distancia.',
      'Great to see your interest in Chaos products.': 'Es fantástico ver tu interés en los productos Chaos.',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        'Si actualmente estás con una versión de prueba, has tenido una antes o ya eres parte de nuestra comunidad, estamos aquí para ayudarte.',
      'Please enter your phone number. We will send you a one-time verification code':
        'Por favor, introduce tu número de teléfono. Te enviaremos un código de verificación único',
      'Phone number': 'Número de teléfono',
      'You will get exclusive updates and training materials':
        'Recibirás actualizaciones exclusivas y materiales de capacitación',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  pt: {
    translation: {
      lang: 'pt',
      'Your trial is a step away': 'Seu  trial está a apenas um passo',
      business: 'Comercial',
      student: 'Estudante',
      educator: 'Educador',
      individual: 'Individual',
      'Verify your account.': 'Verifique sua conta',
      'Tool of choice': 'Ferramenta de escolha',
      'Which best describes your role?': 'O que melhor descreve seu cargo?',
      'What is your role?': 'Qual é o seu cargo?',
      'Business Owner': 'Proprietário',
      'C-Suite | Managing Partner': 'C-Suite | Managing Partner',
      Consultant: 'Consultor',
      Other: 'Outro',
      'What industry are you in?': 'Em qual indústria você está?',
      'What is your field of practice?': 'Qual é seu campo de atuação?',
      "What is your organization's name?": 'Qual é o nome da sua organização?',
      'What is your field of study?': 'Qual é o seu campo de estudo?',
      "What is your university's name?": 'Qual é o nome de sua universidade?',
      'Customize your trial experience.': 'Customize sua experiência.',
      'Welcome to Chaos!': 'Bem-vindo à Chaos!',
      'Education | Academia': 'Educação | Academia',
      'Company name': 'Nome da empresa',
      'Educational institution': 'Instituição educacional',
      'What is your primary tool of choice?': 'Qual é sua ferramenta principal de escolha?',
      'What operating system will you use?': 'Qual sistema operacional você usará?',
      'Host platform': 'Plataforma',
      'What is your current occupation?': 'Qual é a sua ocupação atual?',
      professional: 'Professional',
      Administrator: 'Administrador',
      'Application Engineer': 'Engenheiro de aplicação',
      Architect: 'Arquiteto',
      'BIM Specialist': 'Especialista BIM',
      'Construction Specialist': 'Especialista em Construção',
      'Designer - Interior': 'Designer de Interiores',
      'Designer - Product': 'Designer de Produto',
      'Director / Principal / Team Leader': 'Diretor / Líder de Equipe',
      'Educator / Professor / Teacher': 'Educador / Professor',
      'Procurement / Admin': 'Aquisições / Administração',
      Engineer: 'Engenheiro',
      'Operations Manager': 'Gerente Operacional',
      'Marketing Specialist': 'Especialista em Marketing',
      'Project Manager': 'Gerente de Projetos',
      Student: 'Estudante',
      'Visualization Specialist | 3D Artist': 'Especialista em Visualização / Artista 3D',
      'Media & Entertainment': 'Mídia & Entretenimento',
      Architecture: 'Arquitetura',
      Automotive: 'Automotivo',
      'Building operations and management': 'Operações Prediais & gerenciamento',
      Construction: 'Construção',
      Consulting: 'Consultoria',
      'Education/Academia': 'Educação / Acadêmico',
      Engineering: 'Engenharia',
      'Interior design': 'Design de Interiores',
      'Landscape architecture': 'Paisagismo',
      'Product design': 'Design de Produto',
      'Real estate & Development': 'Imobiliária & Incorporação',
      'Urban planning': 'Planejamento Urbano',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        'Seus créditos Chaos Cloud estão ativos e disponíveis pelos próximos 30 dias.',
      "We've sent an email with further information to": 'Nós já enviamos um e-mail com mais informações para',
      "You'll need to download all the products you'd like to try individually.":
        'Você vai precisar baixar todos os produtos que quer experimentar individualmente.',
      'Find out': 'Descubra',
      'how to install': 'como instalar',
      'This helps us confirm your identity and secure your account':
        'Isto nos ajuda a confirmar sua identidade e deixar sua conta segura.',
      "We've just sent a text message with a 6-digit verification code to":
        'Acabamos de enviar uma mensagem de texto com um código de verificação de 6 dígitos para',
      'Verification code': 'Código de verificação',
      'Verify account': 'Verificar conta',
      "Didn't get the code?": 'Não recebeu o código?',
      'Note that this can take a few minutes': 'Note que pode levar alguns minutos',
      'Resend the code': 'Reenviar código',
      'Please enter your phone number': 'Por favor, digite seu telefone',
      'We will send you a one-time verification code': 'Nós enviaremos um código único de verificação para você',
      'Country code': 'Código do país',
      Number: 'Número',
      'Get verification code': 'Receber código de verificação',
      'Thanks for your interest in Chaos products. It seems that you already received a trial.':
        'Obrigado por seu interesse nos produtos Chaos. Aparentemente você já recebeu seu trial.',
      'You can check your active and expired licenses in your account.':
        'Você pode verificar suas licenças ativas e expiradas na <1>sua conta</1>.',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        'Quer comprar uma nova licença? Visite nossa <1>página de preços</1> e encontre o que precisa.',
      'Need help? Visit our help center for quick answers.':
        'Precisa de ajuda? Visite nossa <1>central de ajuda</1> para respostas rápidas.',
      'We’d love to have you back!': 'Vamos adorar ter você de volta!',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        'Você já testou a Chaos Cloud. Você pode adicionar mais créditos à sua conta através do <2>nosso website</2> ou sua <5>revenda de preferência</5>.',
      'Please select': 'Selecione',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        'Você já possui uma licença {{betaProduct}}. Sua licença é válida durante o período de beta oficial.',
      'If you have any questions, visit our forum.': 'Se você tiver perguntas, <1>visite nosso fórum</1>.',
      'Thank you for joining the {{betaProduct}}.': 'Obrigado por juntar-se ao {{betaProduct}}.',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        'Sua licença beta está ativa. Agora, você pode começar a testar as novas features e melhorias que introduzimos. Sua licença será válida por todo o período oficial de beta. Nós enviamos um e-mail com mais informações para',
      'Download beta': 'Baixar beta',
      'Start rendering': 'Comece a renderizar',
      // Chaos Vantage
      'Download product': 'Baixar produto',
      'Experience real-time like never before.': 'Experimente real-time como nunca antes. ',
      'Your product is now ready for download.': 'Seu produto está pronto para ser baixado.',
      'This promotional license is free of charge and valid until December 31, 2022.':
        'Esta licença promocional é gratuita até 21 de Setembro de 2022.',
      'If you’d like to download more than one license, please contact our friendly support team.':
        'Se você quiser baixar mais de uma licença, por favor <2>contate nossa equipe de suporte</2>.',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray Personal Learning Edition (PLE) for Maya',
      'Fill in your field of teaching': 'Preencha sua área de ensino',
      'Which university/training center do you teach at?': 'Em qual universidade/centro de formação você leciona?',
      'Your phone number': 'Seu número de telefone',
      'Change occupation': 'Mudar profissão',
      'Fill in your industry': 'Preencha sua área de atuação',
      'Fill in your field of study': 'Preencha sua área de estudo',
      'What is your university’s/training center’s name?': 'Qual é o nome da sua universidade/centro de formação?',
      '(optional)': '(opcional)',
      'How often do you typically use V-Ray PLE?': 'Com que frequência você costuma usar o V-Ray PLE?',
      'Do you use any other DCCs apart from Autodesk Maya?': 'Você usa algum outro DCC além do Autodesk Maya?',
      Yes: 'Sim',
      No: 'Não',
      'Please specify which other DCCs you’re using.': 'Por favor, especifique quais outros DCCs você está usando.',
      'Do you use any other renderers?': 'Você usa algum outro renderizador?',
      'Please specify which other renderer(s) you’re using.':
        'Por favor, especifique quais outros renderizadores você está usando.',
      'How long do you plan to use V-Ray PLE?': 'Por quanto tempo você planeja usar o V-Ray PLE?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'Por favor, compartilhe qualquer feedback sobre sua experiência com o V-Ray PLE e os recursos de aprendizagem disponíveis.',
      'Share your work done with V-Ray PLE with us.': 'Compartilhe conosco seu trabalho realizado com o V-Ray PLE.',
      Submit: 'Enviar',
      'Please confirm that your details are up-to-date.': 'Por favor, confirme que seus dados estão atualizados.',
      'Tell us more about your experience with PLE.': 'Conte-nos mais sobre sua experiência com o PLE.',
      'Customize your first steps into V-Ray for Maya.': 'Personalize seus primeiros passos no V-Ray for Maya.',
      'You already have an active V-Ray PLE for Maya license.':
        'Você já possui uma licença ativa do V-Ray PLE para Maya.',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        'Sua licença é válida por 90 dias e pode ser renovada nos 7 dias que antecedem a expiração.',
      'If it expires, you can request a new one by completing the form again.':
        'Se expirar, você pode solicitar uma nova preenchendo novamente o formulário.',
      'Follow the steps here': 'Siga os passos aqui',
      'Thank you': 'Obrigado',
      'Your license is now active, and will be available for the':
        'Sua licença está agora ativa e estará disponível para os',
      'next 90 days': 'próximos 90 dias',
      Download: 'Download',
      Animation: 'Animação',
      Advertising: 'Propaganda',
      'Education / Academia': 'Educação / Academia',
      Gaming: 'Jogos',
      'Product Design': 'Design de Produto',
      'Visualization Specialist / 3D Artist': 'Especialista em Visualização / Artista 3D',
      'Technical Director': 'Diretor Técnico',
      'CG / VFX Supervisor': 'Supervisor de CG / VFX',
      'I didn’t have time to test it': 'Não tive tempo para testá-lo',
      'I want to keep learning': 'Quero continuar aprendendo',
      'I have a personal/university project to complete': 'Tenho um projeto pessoal/universitário para completar',
      'I am attending a rendering class/course': 'Estou frequentando uma aula/curso de renderização',
      'Every day': 'Todos os dias',
      'Every week': 'Toda semana',
      'Once a month': 'Uma vez por mês',
      'Occasionally to complete a project/class': 'Ocasionalmente para completar um projeto/aula',
      'One month': 'Um mês',
      'Three months': 'Três meses',
      'Six months': 'Seis meses',
      'As long as I need to learn V-Ray': 'Enquanto eu precisar aprender V-Ray',
      'Not sure, just testing for now': 'Não tenho certeza, apenas testando por enquanto',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        'Ao continuar, aceito a <privacy>Política de Privacidade</privacy>, <terms>Termos de Uso</terms> e <eula>EULA</eula>.',
      'What is your field of teaching?': 'Qual é sua área de ensino?',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        'Qual é o motivo principal para renovar sua licença V-Ray PLE?',
      Confirm: 'Confirmar',
      "Let's customize your trial experience.": 'Vamos personalizar sua experiência de teste.',
      'Tell us a bit more about yourself.': 'Conte-nos um pouco mais sobre você.',
      'Field of practice': 'Área de atuação',
      Role: 'Cargo',
      'Organization name': 'Nome da empresa',
      'Do you have colleagues interested in licenses as well?': 'Você tem colegas interessados em licenças também?',
      "It's just me": 'Sou apenas eu',
      "We're happy to have you onboard!": 'Estamos felizes em tê-lo a bordo!',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        'Agora você pode explorar tudo incluído em seu teste gratuito de {{duration}} dias.',
      'Download your trial': 'Baixe seu teste',
      Continue: 'Continuar',
      'Start your free trial': 'Iniciar seu período de teste gratuito',
      Back: 'Voltar',
      'Welcome back!': 'Bem-vindo de volta!',
      'Manage your licenses': 'Gerencie suas licenças',
      'Discover plans & pricing': 'Descubra planos e preços',
      'Need a hand?': 'Precisa de uma mão?',
      'personalized-assistance':
        'Nosso time de vendas está pronto para dar a você uma <assistance>assistência personalizada</assistance>.',
      'Your trial download is just moments away.': 'Seu download de teste está prestes a acontecer.',
      'Great to see your interest in Chaos products.': 'É ótimo ver seu interesse nos produtos Chaos.',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        'Se você está atualmente usando um trial, já usou um antes ou já é parte de nossa comunidade, estamos aqui para você.',
      'Please enter your phone number. We will send you a one-time verification code':
        'Favor inserir seu número de telefone. Nós enviaremos um código de verificação único.',
      'Phone number': 'Número de telefone',
      'You will get exclusive updates and training materials':
        'Você receberá atualizações exclusivas e materiais de treinamento',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  cn: {
    translation: {
      lang: 'zh-cn',
      'Your trial is a step away': '申请试用仅一步之遥',
      'Customize your trial experience.': '个性化定制您的试用体验',
      'What is your current occupation?': '您目前从事什么职业？',
      'What is your field of practice?': '您从事什么领域的工作？',
      'What is your role?': '您在工作中担任什么角色？',
      "What is your organization's name?": '您所在的组织名称是什么？',
      optional: '可选项',
      'What is your field of study?': '您的研究领域是什么？',
      "What is your university's name?": '您所在大学的名称是什么？',
      'Welcome to Chaos!': '欢迎使用 Chaos !',
      Administrator: '管理员',
      'Application Engineer': '应用工程师',
      Architect: '建筑师',
      'BIM Specialist': 'BIM 专家',
      'Business Owner': '企业家',
      'C-Suite | Managing Partner': '高管 | 管理合伙人',
      'Construction Specialist': '建筑工程专家',
      Consultant: '顾问',
      'Designer - Interior': '室内设计师',
      'Designer - Product': '产品设计师',
      'Director / Principal / Team Leader': '主管 / 负责人 / 团队领导',
      'Educator / Professor / Teacher': '教育工作者 / 教授 / 教师',
      'Procurement / Admin': '采购 / 管理',
      Engineer: '工程师',
      'Operations Manager': '运营经理',
      'Marketing Specialist': '市场营销专员',
      'Project Manager': '项目经理',
      Student: '学生',
      'Visualization Specialist | 3D Artist': '可视化专家 | 3D艺术家',
      'Media & Entertainment': '媒体与娱乐行业',
      Architecture: '建筑行业',
      Automotive: '汽车行业',
      'Building operations and management': '建筑运维及管理',
      Construction: '建筑工程',
      Consulting: '咨询行业',
      'Education/Academia': '教育/学术',
      Engineering: '工程学',
      'Interior design': '室内设计',
      'Landscape architecture': '景观设计',
      'Product design': '产品设计',
      'Real estate & Development': '房地产及开发',
      'Urban planning': '城市规划',
      business: '行业',
      student: '学生',
      educator: '教师',
      individual: '个人',
      'Verify your account.': '验证您的账户',
      'Tool of choice': '选择应用',
      'Which best describes your role?': '您从事什么职业？',
      Other: '其他',
      'What industry are you in?': '您从事什么行业？',
      'Education | Academia': '教育|研究',
      'Company name': '公司名称',
      'Educational institution': '教育机构',
      'What is your primary tool of choice?': '哪个是您的首选应用？',
      'What operating system will you use?': 'What operating system will you use?',
      'Host platform': '主要平台',
      professional: 'Professional',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        '您的 Chaos Cloud 信用点数已经激活，有效期为30天。',
      "We've sent an email with further information to": '我们已将包含更多信息的电子邮件发送至',
      "You'll need to download all the products you'd like to try individually.": '您需要单独下载任何您想体验的产品。',
      'Find out': '查看更多',
      'how to install': '如何安装',
      'This helps us confirm your identity and secure your account': '这将有助于我们验证您的身份并确保您的账户安全',
      "We've just sent a text message with a 6-digit verification code to": '我们刚刚发送了一条带有6位数验证码到',
      'Verification code': '验证码',
      'Verify account': '验证账户',
      "Didn't get the code?": '没有收到验证码？',
      'Note that this can take a few minutes': '注意这可能需要几分钟',
      'Resend the code': '重新发送',
      'Please enter your phone number': '请输入您的电话号码',
      'We will send you a one-time verification code': '我们将向您发送一个一次性验证码',
      'Country code': '国家（地区）代码',
      Number: '号码',
      'Get verification code': '获取验证码',
      'Thanks for your interest in Chaos products. It seems that you already received a trial.':
        '感谢您对 Chaos 产品感兴趣。您似乎已经收到了试用版。',
      'You can check your active and expired licenses in your account.':
        '您可以在<1>管理帐号</1>中查看有效期内和已过期的授权许可证。',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        '想要购买新的授权许可？请访问我们的<1>价格页面</1>并找到您所需的产品。',
      'Need help? Visit our help center for quick answers.': '需要帮助吗？请访问我们的<1>帮助中心</1>获得快速解答。',
      'We’d love to have you back!': '我们非常期待您再次光临！',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        '您已体验了 Chaos Cloud。您可以通过<2>我们的网站</2> 或您的 <5>首选代理商</5>为您的账户购买更多点数。',
      'Please select': '请选择',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        '您已获得 {{betaProduct}} 测试版授权许可。您的授权许可在整个官方测试期间有效。',
      'If you have any questions, visit our forum.': '如果您有任何问题，<1>请访问我们官方论坛</1>。',
      'Thank you for joining the {{betaProduct}}.': '感谢您参与 {{betaProduct}} 测试。',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        '您的 Beta 版授权许可现已激活。现在，您可以开始测试我们推出的所有新功能和改进。您的授权许可将在整个官方测试期间有效。我们已经发送了一封包含更多信息的电子邮件至',
      'Download beta': '下载测试版',
      'Start rendering': '开始渲染',
      // Chaos Vantage
      'Download product': '下载产品',
      'Experience real-time like never before.': '前所未有的实时体验。',
      'Your product is now ready for download.': '现在即可下载您的产品。',
      'This promotional license is free of charge and valid until December 31, 2022.':
        '此免费授权许可，有效期至 2022 年 9 月 21 日。',
      'If you’d like to download more than one license, please contact our friendly support team.':
        '如果您需要下载使用多个授权许可，请<2>联系我们的技术支持团队</2>。',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray for Maya 个人学习版 (PLE) ',
      'Fill in your field of teaching': '填写您的教学领域',
      'Which university/training center do you teach at?': '您在哪所大学或培训中心任教？',
      'Your phone number': '您的手机号码',
      'Change occupation': '更改职业',
      'Fill in your industry': '填写您所在的行业',
      'Fill in your field of study': '填写您所在的行业',
      'What is your university’s/training center’s name?': '您所在大学/培训中心的名称是什么?',
      '(optional)': '(可选项)',
      'How often do you typically use V-Ray PLE?': '您通常多久使用一次 V-Ray PLE?',
      'Do you use any other DCCs apart from Autodesk Maya?':
        '除了 Autodesk Maya 之外，您还使用其他 DCC（数字内容创建）工具吗？',
      Yes: '是',
      No: '否',
      'Please specify which other DCCs you’re using.': '请列出您使用的其他 DCC 工具。',
      'Do you use any other renderers?': '您是否还使用其他渲染器?',
      'Please specify which other renderer(s) you’re using.': '请列出您正在使用的其他渲染器',
      'How long do you plan to use V-Ray PLE?': '您计划使用 V-Ray PLE 多长时间?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        '请分享您对 V-Ray PLE 和现有学习资源使用体验的反馈意见。',
      'Share your work done with V-Ray PLE with us.': '与我们分享您使用 V-Ray PLE 完成的作品。',
      Submit: '提交',
      'Please confirm that your details are up-to-date.': '请确认您的信息是否为最新信息。',
      'Tell us more about your experience with PLE.': '请与我们分享更多您使用 PLE 的体验。',
      'Customize your first steps into V-Ray for Maya.': '为初次使用 V-Ray for Maya 进行自定义设置。',
      'You already have an active V-Ray PLE for Maya license.': '您已经拥有一个活跃的 V-Ray PLE for Maya 许可证',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        '您的授权许可证有效期为 90 天，可以在到期前 7 天内续订。',
      'If it expires, you can request a new one by completing the form again.':
        '如果已过期，您可以通过再次填写表格来申请新的授权许可证。',
      'Follow the steps here': '请按以下步骤操作',
      'Thank you': '谢谢',
      'Your license is now active, and will be available for the': '您的授权许可证现已激活，可用于',
      'next 90 days': '下一个 90 天',
      Download: '下载',
      Animation: '动画',
      Advertising: '广告',
      'Education / Academia': '教育/学术',
      Gaming: '游戏',
      'Product Design': '产品设计',
      'Visualization Specialist / 3D Artist': '可视化专家/3D 艺术家',
      'Technical Director': '技术总监',
      'CG / VFX Supervisor': 'CG / VFX 主管',
      'I didn’t have time to test it': '我没有时间进行测试',
      'I want to keep learning': '我希望继续学习',
      'I have a personal/university project to complete': '我有一项个人/大学项目要完成',
      'I am attending a rendering class/course': '我正在参加渲染课程/培训班',
      'Every day': '每天',
      'Every week': '每周',
      'Once a month': '每月一次',
      'Occasionally to complete a project/class': '偶尔完成项目/课程',
      'One month': '一个月',
      'Three months': '三个月',
      'Six months': '六个月',
      'As long as I need to learn V-Ray': '只要我还需要学习 V-Ray',
      'Not sure, just testing for now': '不确定，目前只是测试',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        '选择继续，即表示我接受 <privacy>隐私政策</privacy>, <terms>使用条款</terms>, 以及 <eula>最终用户许可协议(EULA)</eula>',
      'What is your field of teaching?': '您的教学领域是什么？',
      'What’s the primary reason for renewing your V-Ray PLE license?': '您续订 V-Ray PLE 许可证的主要原因是什么？',
      Confirm: '确认',
      "Let's customize your trial experience.": '自定义您的试用体验',
      'Tell us a bit more about yourself.': '请与我们分享关于您的更多信息。',
      'Field of practice': '工作领域',
      Role: '职位',
      'Organization name': '组织名称',
      'Do you have colleagues interested in licenses as well?': '您的同事也对授权许可证感兴趣吗？',
      "It's just me": '只有我',
      "We're happy to have you onboard!": '我们很高兴您的参与',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        '现在您可以探索 {{duration}} 天免费试用版中的全部内容。',
      'Download your trial': '下载试用版',
      Continue: '继续',
      'Start your free trial': '开始免费试用',
      Back: '返回',
      'Welcome back!': '欢迎回来！',
      'Manage your licenses': '管理您的授权许可证。',
      'Discover plans & pricing': '了解订阅计划及价格',
      'Need a hand?': '需要帮助？',
      'personalized-assistance': '我们的销售团队随时为您提供<assistance>个性化帮助</assistance>。',
      'Your trial download is just moments away.': '即将开始下载试用版。',
      'Great to see your interest in Chaos products.': '很高兴看到您对 Chaos 产品感兴趣。',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        '如果您正在试用，或曾经试用过，亦或者已经是我们社区中的一员，我们将竭诚为您服务。',
      'Please enter your phone number. We will send you a one-time verification code':
        '请填写您的手机号码，我们会向您发送一条一次性验证码。',
      'Phone number': '手机号码',
      'You will get exclusive updates and training materials': 'You will get exclusive updates and training materials',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  kr: {
    translation: {
      lang: 'ko',
      'Your trial is a step away': '평가판 신청이 거의 진행되었습니다.',
      'Customize your trial experience.': '평가판 체험 커스터마이팅하기',
      'What is your current occupation?': '현재 직업은 무엇입니까?',
      'What is your field of practice?': '귀하의 업무 분야는 무엇입니까?',
      'What is your role?': '귀하의 역할은 무엇입니까?',
      "What is your organization's name?": '귀하의 조직(회사) 이름은 무엇입니까?',
      optional: '옵션',
      'What is your field of study?': '전공분야는 무엇입니까?',
      "What is your university's name?": '귀하가 나오신 대학명은 어떻게 되십니까?',
      'Welcome to Chaos!': 'Chaos에 오신것을 환영합니다.d',
      Administrator: '관리자',
      'Application Engineer': '어플 엔지니어',
      Architect: '건축가',
      'BIM Specialist': 'BIM 전문가',
      'Business Owner': '사업가',
      'C-Suite | Managing Partner': 'C-Suite | 관리파트너',
      'Construction Specialist': '건설 전문가',
      Consultant: '컨설턴트',
      'Designer - Interior': '인테리어 디자이너',
      'Designer - Product': '제품 디자이너',
      'Director / Principal / Team Leader': '이사 / 교장 / 팀 리더',
      'Educator / Professor / Teacher': '교육자 / 교수 / 선생님',
      'Procurement / Admin': '조달 / 관리',
      Engineer: '엔지니어',
      'Operations Manager': '작업 관리자',
      'Marketing Specialist': '마케팅 전문가',
      'Project Manager': '프로젝트 매니저',
      Student: '학생',
      'Visualization Specialist | 3D Artist': '시각화 전문가 | 3D 아티스트',
      'Media & Entertainment': '미디어 엔터테인먼트',
      Architecture: '건축',
      Automotive: '자동차',
      'Building operations and management': '건물 운영 및 관리',
      Construction: '건설',
      Consulting: '컨설팅',
      'Education/Academia': '교육/학술',
      Engineering: '공학',
      'Interior design': '인테리어 디자인',
      'Landscape architecture': 'Landscape 건축',
      'Product design': '제품 디자인',
      'Real estate & Development': '부동산 개발',
      'Urban planning': '도시계획',
      business: '업무용',
      student: '학생',
      educator: '교육자',
      individual: '개인',
      'Verify your account.': '계정 확인',
      'Tool of choice': '제품 선택',
      'Which best describes your role?': '귀하의 업무에 대한 설명에 가장 맞는것은?',
      Other: '기타 외',
      'What industry are you in?': '당신은 어떤 업계에 있습니까?',
      'Education | Academia': '교육|학계',
      'Company name': '회사 이름',
      'Educational institution': '교육 기관',
      'What is your primary tool of choice?': '귀하가 주로 사용하는 제품은 무엇입니까?',
      'What operating system will you use?': '어떤 OS를 사용하실 계획인가요?',
      'Host platform': '메인 플랫폼',
      professional: 'Professional',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        '이제 Chaos Cloud 크레딧이 활성화되었으며 앞으로 30일 동안 사용할 수 있습니다.',
      "We've sent an email with further information to": '추가 정보가 포함된 이메일을 보내드렸습니다.',
      "You'll need to download all the products you'd like to try individually.":
        '평가하시려는 제품을 개별적으로 다운로드 하셔야 합니다.',
      'Find out': '찾아보기',
      'how to install': '인스톨 하는법',
      'This helps us confirm your identity and secure your account':
        '이는 귀하의 신원을 확인하고 귀하의 계정을 보호하는 데 도움이 됩니다.',
      "We've just sent a text message with a 6-digit verification code to":
        '6자리 인증 코드가 포함된 문자 메시지를 보내드렸습니다.',
      'Verification code': '확인 코드',
      'Verify account': '계정 확인',
      "Didn't get the code?": '코드를 받지 못하셨다구요?',
      'Note that this can take a few minutes': '몇 분 정도 걸릴 수 있습니다.',
      'Resend the code': '코드 재발송하기',
      'Please enter your phone number': '전화번호를 입력하세요',
      'We will send you a one-time verification code': '일회성 인증 코드를 보내드립니다',
      'Country code': '국가코드',
      Number: '번호',
      'Get verification code': '인증 코드 받기',
      'Thanks for your interest in Chaos products. It seems that you already received a trial.':
        '카오스 제품에 관심을 가져주셔서 감사합니다. 귀하께서는 이미 임시라이선스를 발급 받으신 것 같습니다.',
      'You can check your active and expired licenses in your account.':
        '<1>계정</1>에서 활성 및 만료된 라이선스를 확인할 수 있습니다.',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        '새 라이선스를 구매하시겠습니까? <1>가격 페이지</1>를 방문하여 필요한 것을 찾으십시오.',
      'Need help? Visit our help center for quick answers.':
        '도움이 필요하십니까? 빠른 답변을 받으려면 <1>도움말 센터</1>를 방문하세요.',
      'We’d love to have you back!': '저희 제품으로 다시 돌아오시길 기다리겠습니다.',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        '당신은 이미 카오스 클라우드를 테스트했습니다. 당사 <2>웹사이트</2>나 선호하는 <5>리셀러를</5> 통해 계정에 크레딧을 더 추가할 수 있습니다.',
      'Please select': '선택해주세요',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        '이미 {{betaProduct}} 베타 라이센스가 있습니다. 라이선스는 공식 베타 기간 동안 유효합니다.',
      'If you have any questions, visit our forum.': '질문이 있는 경우 <1>포럼을 이용</1>하여주십시오.',
      'Thank you for joining the {{betaProduct}}.': '{{betaProduct}} 베타에 참여해주셔서 감사합니다.',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        '베타 라이선스가 활성화되었습니다. 이제 저희 신규 버전의 모든 새로운 기능과 개선 사항을 테스트할 수 있습니다. 라이선스는 공식 베타 테스트 기간 동안 유효합니다. 추가 정보가 포함된 이메일을 발송하였습니다.',
      'Download beta': '베타 라이선스 다운받기',
      'Start rendering': '렌더링 시작하기',
      // Chaos Vantage
      'Download product': '제품 다운로드',
      'Experience real-time like never before.': '지금까지 경험하지 못한 실시간 경험.',
      'Your product is now ready for download.': '이제 제품을 다운로드할 준비가 되었습니다.',
      'This promotional license is free of charge and valid until December 31, 2022.':
        ' 이 프로모션 라이선스는 무료이며 2022년 9월 21일까지 유효합니다.',
      'If you’d like to download more than one license, please contact our friendly support team.':
        '라이선스를 두 개 이상 다운로드하려면 <2>저희의 친절한 지원 팀에 문의</2>하세요.',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'Maya용 V-Ray 개인 학습 에디션(PLE)',
      'Fill in your field of teaching': '정보를 입력해주세요',
      'Which university/training center do you teach at?': '어느 대학/훈련 센터에서 가르치고 계시나요?',
      'Your phone number': '연락처를 적어주세요',
      'Change occupation': '직업 변경하기',
      'Fill in your industry': '귀하의 업종을 입력해주세요',
      'Fill in your field of study': '전공 분야를 입력해주세요',
      'What is your university’s/training center’s name?': '귀하의 대학/훈련 센터 이름은 무엇입니까?',
      '(optional)': '(옵션)',
      'How often do you typically use V-Ray PLE?': '보통 V-Ray PLE를 얼마나 자주 사용하십니까?',
      'Do you use any other DCCs apart from Autodesk Maya?': 'Autodesk Maya 외에 사용하시는 메인 제품이 있으십니까',
      Yes: '네, 있습니다',
      No: '아니오, 없습니다',
      'Please specify which other DCCs you’re using.': '사용중인 다른 메인 제품을 선택해주세요',
      'Do you use any other renderers?': '사용중인 다른 렌더러가 있으십니까?',
      'Please specify which other renderer(s) you’re using.': '사용 중인 다른 렌더러를 선택해주세요',
      'How long do you plan to use V-Ray PLE?': 'V-Ray PLE를 얼마나 오랫동안 사용할 계획입니까?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'V-Ray PLE 사용 경험과 제공되는 학습 자료들에 대한 피드백을 공유해 주세요.',
      'Share your work done with V-Ray PLE with us.': 'V-Ray PLE로 수행한 작품을 저희와 공유해주시겠습니까?.',
      Submit: '제출하기',
      'Please confirm that your details are up-to-date.': '귀하의 세부 정보가 최신인지 확인해주세요',
      'Tell us more about your experience with PLE.': 'PLE에 대한 귀하의 경험에 대해 더 자세히 알고싶습니다.',
      'Customize your first steps into V-Ray for Maya.': 'V-Ray for Maya의 첫 번째 단계를 진행해주세요.',
      'You already have an active V-Ray PLE for Maya license.': '이미 활성화된 V-Ray PLE for Maya 라이센스가 있습니다.',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        '귀하의 라이센스는 90일 동안 유효하며 만료되기 전 7일 이내에 갱신할 수 있습니다.',
      'If it expires, you can request a new one by completing the form again.':
        '만료된 경우 양식을 다시 작성하여 새로운 라이선스를 요청할 수 있습니다.',
      'Follow the steps here': '다음의 단계를 따라서 진행해주세요',
      'Thank you': '감사합니다',
      'Your license is now active, and will be available for the':
        '귀하의 라이센스는 이제 활성화되었으며 다음 기간 동안 사용할 수 있습니다.',
      'next 90 days': '다음 90일간',
      Download: '다운로드',
      Animation: '애니메이션',
      Advertising: '광고',
      'Education / Academia': '교육기관 / 학원',
      Gaming: '게임',
      'Product Design': '제품디자인',
      'Visualization Specialist / 3D Artist': '시각화 전문가 / 3D 아티스트',
      'Technical Director': '기술디렉터',
      'CG / VFX Supervisor': 'CG / VFX 매니저',
      'I didn’t have time to test it': '아직 테스트하지 못했습니다',
      'I want to keep learning': '계속 테스트하고 싶습니다',
      'I have a personal/university project to complete': '완료해야 할 개인/대학 프로젝트가 있습니다.',
      'I am attending a rendering class/course': '렌더링 수업/과정에 참석하고 있습니다',
      'Every day': '매일',
      'Every week': '매주',
      'Once a month': '한 달에 한번',
      'Occasionally to complete a project/class': '가끔 프로젝트/수업을 위해',
      'One month': '한 달',
      'Three months': '3개월',
      'Six months': '6개월',
      'As long as I need to learn V-Ray': 'V-Ray를 배워야 하는 동안 계속',
      'Not sure, just testing for now': '확실하지 않습니다. 지금은 테스트 중입니다.',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        '계속 진행하시면 <privacy>개인정보 보호정책</privacy>, <terms>이용 약관</terms> 및 <eula>EULA에 동의하게 됩니다.</eula>',
      'What is your field of teaching?': '교육하시는 분야는 무엇입니까?',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        'V-Ray PLE 라이센스를 갱신하는 주된 이유는 무엇입니까?',
      Confirm: '확인',
      "Let's customize your trial experience.": '평가판을 통해 자신만의 새로운 경험을 즐겨보세요.',
      'Tell us a bit more about yourself.': '귀하에 대해 좀 더 알려주세요',
      'Field of practice': '실무분야',
      Role: '역할',
      'Organization name': '조직명',
      'Do you have colleagues interested in licenses as well?': '혹시 라이선스에 관심이 있는 다른 동료도 있나요?',
      "It's just me": '저 혼자입니다.',
      "We're happy to have you onboard!": '귀하를 모시게 되어 기쁘게 생각합니다.',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        '이제 {{duration}}일 무료 평가판에 포함된 모든 것들을 사용할수 있습니다.',
      'Download your trial': '평가판 다운로드하기',
      Continue: '계속 진행하기',
      'Start your free trial': '평가판 시작하기',
      Back: '뒤로 돌아가기',
      'Welcome back!': '돌아와주셔서 감사합니다!',
      'Manage your licenses': '라이선스를 쉽게 관리하세요.',
      'Discover plans & pricing': '요금제 및 가격 알아보기',
      'Need a hand?': '도움이 필요하세요?',
      'personalized-assistance':
        '우리 세일즈팀에서는 언제든지 <assistance>귀하에 적합한 제안</assistance>을 드릴 준비가 되어있습니다.',
      'Your trial download is just moments away.': '평가판 다운로드가 곧 완료됩니다.',
      'Great to see your interest in Chaos products.': 'Chaos 제품에 관심을 가져주셔서 반갑습니다.',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        '현재 평가판을 사용 중이거나 이전에 평가판을 사용했거나 이미 커뮤니티에 속해 계시다면 저희가 도와드리겠습니다.',
      'Please enter your phone number. We will send you a one-time verification code':
        '전화번호를 입력해주세요. 일회성 인증 코드를 보내드립니다',
      'Phone number': '전화번호',
      'You will get exclusive updates and training materials': 'You will get exclusive updates and training materials',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  jp: {
    translation: {
      lang: 'ja',
      'Your trial is a step away': 'トライアルまでもう少しです。',
      'Customize your trial experience.': 'トライアルの内容をカスタマイズします。',
      'What is your current occupation?': 'あなたの現在の職業は何ですか？',
      'What is your field of practice?': 'あなたの職種は何ですか？',
      'What is your role?': 'あなたの役割は何ですか？',
      "What is your organization's name?": 'あなたの部署名は何ですか？',
      optional: '任意です',
      'What is your field of study?': 'あなたの研究分野は何ですか？',
      "What is your university's name?": 'あなたの大学の名前は何ですか？',
      'Welcome to Chaos!': 'Chaosへようこそ！',
      Administrator: 'アドミニストレーター',
      'Application Engineer': 'アプリケーションエンジニア',
      Architect: '建築家',
      'BIM Specialist': 'BIMスペシャリスト',
      'Business Owner': '経営者',
      'C-Suite | Managing Partner': 'C-Suite | マネージングパートナー',
      'Construction Specialist': 'コンストラクションスペシャリスト',
      Consultant: 'コンサルタント',
      'Designer - Interior': 'インテリアデザイナー',
      'Designer - Product': 'プロダクトデザイナー',
      'Director / Principal / Team Leader': 'ディレクター / プリンシパル / チームリーダー',
      'Educator / Professor / Teacher': '教育者 / 教授 / 先生',
      'Procurement / Admin': '調達 / 管理',
      Engineer: 'エンジニア',
      'Operations Manager': 'オペレーションマネージャー',
      'Marketing Specialist': 'マーケティングスペシャリスト',
      'Project Manager': 'プロジェクトマネージャ',
      Student: '学生',
      'Visualization Specialist | 3D Artist': 'ビジュアライゼーションスペシャリスト | 3Dアーティスト',
      'Media & Entertainment': 'メディア・エンターテインメト',
      Architecture: '建築',
      Automotive: '自動車',
      'Building operations and management': 'ビルの運営・管理',
      Construction: '建設',
      Consulting: 'コンサルティング',
      'Education/Academia': '教育／学術',
      Engineering: 'エンジニアリング',
      'Interior design': 'インテリアデザイン',
      'Landscape architecture': 'ランドスケープアーキテクチャ',
      'Product design': 'プロダクトデザイン',
      'Real estate & Development': '不動産・開発',
      'Urban planning': '都市計画',
      business: 'ビジネス',
      student: '生徒',
      educator: '教育者',
      individual: '個人',
      'Verify your account.': 'アカウントの確認',
      'Tool of choice': '選択ツール',
      'Which best describes your role?': 'あなたの役割に最も近いのはどれですか？',
      Other: 'その他',
      'What industry are you in?': '属している業界は？',
      'Education | Academia': '教育|学術',
      'Company name': '会社名',
      'Educational institution': '教育機関',
      'What is your primary tool of choice?': 'あなたが主に使っているツールはどれですか？',
      'What operating system will you use?': 'どのOSを使用していますか？',
      'Host platform': 'ホストプラットフォーム',
      professional: 'Professional',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        'Chaos Cloudのクレジットは、今後30日間利用可能です。',
      "We've sent an email with further information to": '詳細な情報をメールで送りました。',
      "You'll need to download all the products you'd like to try individually.":
        '試したい製品は個別にダウンロードする必要があります。',
      'Find out': '検索する',
      'how to install': 'インストールの方法',
      'This helps us confirm your identity and secure your account':
        'これは、お客様の身元を確認し、お客様のアカウントを保護するのに役立ちます。',
      "We've just sent a text message with a 6-digit verification code to":
        '6桁の認証コードを記載したテキストメッセージを送信しました。',
      'Verification code': '認証コード',
      'Verify account': 'アカウントの確認',
      "Didn't get the code?": 'コードを取得できなかった？',
      'Note that this can take a few minutes': '数分かかることがありますのでご注意ください。',
      'Resend the code': 'コードの再送信',
      'Please enter your phone number': '電話番号を入力してください。',
      'We will send you a one-time verification code': 'ワンタイム認証コードをお送りします。',
      'Country code': '国番号',
      Number: '番号',
      'Get verification code': '認証コードを取得する。',
      'Thanks for your interest in Chaos products. It seems that you already received a trial.':
        '弊社製品に関心をお寄せいただきありがとうございます。あなたはすでにトライアルに参加されてるようです。',
      'You can check your active and expired licenses in your account.':
        '<1>あなたのアカウント</1>で有効なライセンスと期限切れのライセンスを確認できます。',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        '新しいライセンスをご希望ですか？<1>価格ページ</1>をご覧いただき、必要なものをお探しください。',
      'Need help? Visit our help center for quick answers.':
        'なにかお困りですか？<1>ヘルプセンター</1>をご覧ください。',
      'We’d love to have you back!': 'ぜひまたお越しください!',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        'あなたはすでにChaosCloudをテスト済みです。私たちの<2>Webサイト</2>またはお好みの<5>リセラー</5>から、さらにクレジットを追加できます。',
      'Please select': '選択してください。',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        'あなたは既に{{betaProduct}}のベータライセンスを持っており、そのライセンスは公式 ベータ期間中有効です。',
      'If you have any questions, visit our forum.': 'ご不明な点がございましたら, <1>フォーラム </1>をご覧ください。',
      'Thank you for joining the {{betaProduct}}.': '{{betaProduct}}ベータ版を申 請頂きありがとうございます。',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        'ベータ版ライセンスがアクティベートされました。 すべての新しい機能と改善点をテストする ことができます。ライセンスは公式ベータテスト期間中有効です。 Eメールアドレスに追加情報を 送信しました。',
      'Download beta': 'ベータ版をダウンロード',
      'Start rendering': 'レンダリングを始める',
      // Chaos Vantage
      'Download product': 'ダウンロード',
      'Experience real-time like never before.': '今までにないリアルタイム性を体験してください。',
      'Your product is now ready for download.': '製品をダウンロードすることができます。',
      'This promotional license is free of charge and valid until December 31, 2022.':
        ' このプロモーションライセンスは無料で、2022年9月21日まで有効です。',
      'If you’d like to download more than one license, please contact our friendly support team.':
        ' 複数のライセンスをダウンロードする場合は、 <2>サポートチームへお問い合わせ</2>ください。',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray Personal Learning Edition (PLE) for Maya',
      'Fill in your field of teaching': '情報を入力してください。',
      'Which university/training center do you teach at?': 'どの大学/トレーニングセンターで働いていますか？',
      'Your phone number': '電話番号を書いてください',
      'Change occupation': '職業変更',
      'Fill in your industry': '業種を入力してください',
      'Fill in your field of study': '専攻分野を入力してください',
      'What is your university’s/training center’s name?': '貴下の大学/トレーニングセンターの名前を入力してください。',
      '(optional)': '(任意です)',
      'How often do you typically use V-Ray PLE?': 'V-Ray PLEをどのくらいの頻度で使いますか？',
      'Do you use any other DCCs apart from Autodesk Maya?': 'Autodesk Maya以外にお使いのメイン製品はありますか？',
      Yes: 'はい',
      No: 'いいえ',
      'Please specify which other DCCs you’re using.': 'お使いの他のメイン製品をを選択してください',
      'Do you use any other renderers?': '使用中の他のレンダラーがありますか？',
      'Please specify which other renderer(s) you’re using.': 'お使いの他のレンダラーを選択してください',
      'How long do you plan to use V-Ray PLE?': 'V-Ray PLEをどのくらい使う予定ですか？',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'V-Ray PLEの使用経験と提供される学習資料に対するフィードバックを共有してください',
      'Share your work done with V-Ray PLE with us.': 'V-Ray PLEで行った作品を私たちと共有していただけますか？',
      Submit: '提出する',
      'Please confirm that your details are up-to-date.': 'お客様の情報が最新であることをご確認ください',
      'Tell us more about your experience with PLE.': 'PLEに関するあなたの経験について教えていただければ幸いです。',
      'Customize your first steps into V-Ray for Maya.': 'V-Ray for Mayaの最初のステップを進めてください。',
      'You already have an active V-Ray PLE for Maya license.':
        '既に有効化されたV-Ray PLE for Mayaライセンスがあります。',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        'ライセンスは90日間有効で、満了する前の7日以内に更新できます。',
      'If it expires, you can request a new one by completing the form again.':
        '期限切れになった場合、様式を再作成して新しいライセンスを要請することができます。',
      'Follow the steps here': '次のステップに沿って進めてください',
      'Thank you': 'ありがとうございます',
      'Your license is now active, and will be available for the':
        'お客様のライセンスは有効になっており、次の期間にご利用いただけます。',
      'next 90 days': '90日間',
      Download: 'ダウンロード',
      Animation: 'アニメーション',
      Advertising: '広告',
      'Education / Academia': '教育/学園',
      Gaming: 'ゲーム',
      'Product Design': '製品デザイン',
      'Visualization Specialist / 3D Artist': 'パースの専門家/3Dアーティスト',
      'Technical Director': 'テクニカル·ディレクター',
      'CG / VFX Supervisor': 'CG / VFX スーパーバイザ',
      'I didn’t have time to test it': 'まだ試したことがありません。',
      'I want to keep learning': '引き続き、試したいです',
      'I have a personal/university project to complete': '個人/大学のプロジェクトを完了する必要があります',
      'I am attending a rendering class/course': 'レンダリングクラス/コースに通っています',
      'Every day': '毎日',
      'Every week': '毎週',
      'Once a month': '月に一回',
      'Occasionally to complete a project/class': 'プロジェクト/クラスのため、たまに使います。',
      'One month': '1ヶ月',
      'Three months': '3ヶ月',
      'Six months': '6ヶ月',
      'As long as I need to learn V-Ray': 'V-Rayを学ぶ必要がある限り',
      'Not sure, just testing for now': '今のところはテストするだけです',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        '引き続き、<privacy>プライバシーポリシー</privacy>、<terms>利用規約</terms>、<eula>EULA</eula>に同意します。',
      'What is your field of teaching?': '教育分野を教えてください。',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        'V-Ray PLEライセンスを更新する主な理由は何ですか？',
      Confirm: '確認',
      "Let's customize your trial experience.": 'お客様に必要なトライアル版をカスタマイズします。',
      'Tell us a bit more about yourself.': 'お客様の情報を入力してください。',
      'Field of practice': '業界',
      Role: '職種',
      'Organization name': '貴社・団体名',
      'Do you have colleagues interested in licenses as well?':
        'ライセンスに興味がある他の同僚様もいらっしゃいますか？',
      "It's just me": '私一人です。',
      "We're happy to have you onboard!": 'トライアル版をお申し込みいただきありがとうございます。',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        '{{duration}}日間無料トライアルに含まれるすべてのものが利用可能になります。',
      'Download your trial': 'トライアル版をダウンロードする',
      Continue: '続ける',
      'Start your free trial': '無料トライアルを開始する',
      Back: '戻る',
      'Welcome back!': 'ようこそ！',
      'Manage your licenses': 'ライセンスを管理できます',
      'Discover plans & pricing': 'プランと価格を見る',
      'Need a hand?': '何かお困りですか？',
      'personalized-assistance':
        '我々のセールスチームでは、いつでも<assistance>お客様に合わせたご提案</assistance>をさせていただきます。',
      'Your trial download is just moments away.': '間もなくトライアル版ダウンロードが可能です。',
      'Great to see your interest in Chaos products.': 'Chaos製品に興味をお持ちいただきありがとうございます。',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        '試用版を使用している方、以前に試用版を使用した方、すでにコミュニティに参加されてる方はこちらでお手伝いいたします。',
      'Please enter your phone number. We will send you a one-time verification code':
        '電話番号を入力してください。 認証コードをお送りいたします。',
      'Phone number': '電話番号',
      'You will get exclusive updates and training materials': '特別なアップデートやトレーニング資料を入手できます。',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  de: {
    translation: {
      lang: 'de',
      'Your trial is a step away': 'Ihre Testversion ist nur einen Schritt entfernt',
      business: 'Business',
      student: 'Student',
      educator: 'Lehrende',
      individual: 'Einzelperson',
      'Verify your account.': 'Verifizieren Sie Ihr Konto.',
      'Tool of choice': 'Präferiertes Tool',
      'Which best describes your role?': 'Was beschreibt Ihre Rolle am besten?',
      'What is your role?': 'Was ist Ihre Rolle?',
      Administrator: 'Administrator',
      'Application Engineer': 'Anwendungsingenieur',
      Architect: 'Architekt',
      'BIM Specialist': 'BIM-Spezialist',
      'Business Owner': 'Geschäftsinhaber',
      'C-Suite | Managing Partner': 'C-Suite | Geschäftsführer',
      'Construction Specialist': 'Spezialist für Bauwesen',
      Consultant: 'Berater',
      'Designer - Interior': 'Designer - Interior',
      'Designer - Product': 'Designer - Product',
      'Director / Principal / Team Leader': 'Direktor / Direktorin / Teamleiter/in',
      'Educator / Professor / Teacher': 'Lehrkraft / Professor / Lehrer',
      'Procurement / Admin': 'Beschaffung / Verwaltung',
      Engineer: 'Ingenieur',
      'Operations Manager': 'Operations Manager',
      'Marketing Specialist': 'Marketing Spezialist',
      'Project Manager': 'Projektleiter',
      Student: 'Student',
      'Visualization Specialist | 3D Artist': 'Visualisierungsspezialist | 3D Artist',
      Other: 'Sonstiges',
      'What industry are you in?': 'In welcher Branche sind Sie tätig?',
      'What is your field of practice?': 'Was ist Ihr Fachgebiet?',
      "What is your organization's name?": 'Wie lautet der Name Ihrer Organisation?',
      'What is your field of study?': 'Was ist Ihr Studienfach?',
      "What is your university's name?": 'Wie heißt Ihre Universität?',
      'Media & Entertainment': 'Medien und Unterhaltung',
      Architecture: 'Architektur',
      Automotive: 'Automobilindustrie',
      'Building operations and management': 'Betrieb und Verwaltung von Gebäuden',
      Construction: 'Bauwesen',
      Consulting: 'Beratung',
      'Education/Academia': 'Bildung/Wissenschaft',
      Engineering: 'Ingenieurwesen',
      'Interior design': 'Inneneinrichtung',
      'Landscape architecture': 'Landschaftsarchitektur',
      'Product design': 'Produktdesign',
      'Real estate & Development': 'Immobilien & Entwicklung',
      'Urban planning': 'Stadtplanung',
      'Company name': 'Name des Unternehmens',
      'Educational institution': 'Bildungseinrichtung',
      'What is your primary tool of choice?': 'Was ist das Tool Ihrer Wahl?',
      'What operating system will you use?': 'Welche Betriebssysteme nutzen Sie?',
      'Host platform': 'Platform',
      'What is your current occupation?': 'Welchen Beruf üben Sie derzeit aus?',
      professional: 'Professional',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        'Ihre Chaos Cloud-Credits sind jetzt aktiv und stehen für die nächsten 30 Tage zur Verfügung.',
      "We've sent an email with further information to":
        'Wir haben eine E-Mail mit weiteren Informationen verschickt an:',
      "You'll need to download all the products you'd like to try individually.":
        'Sie müssen alle Produkte, die Sie ausprobieren möchten, einzeln herunterladen.',
      'Find out': 'Mehr lernen',
      'how to install': 'Installieren',
      'This helps us confirm your identity and secure your account':
        'Dies hilft uns, Ihre Identität zu bestätigen und Ihr Konto zu schützen',
      "We've just sent a text message with a 6-digit verification code to":
        'Wir haben gerade eine SMS mit einem 6-stelligen Bestätigungscode versendet an',
      'Verification code': 'Verifizierungscode',
      'Verify account': 'Konto verifizieren',
      "Didn't get the code?": 'Sie haben den Code nicht erhalten?',
      'Note that this can take a few minutes': 'Beachten Sie, dass dies einige Minuten dauern kann.',
      'Resend the code': 'Senden Sie den Code erneut',
      'Please enter your phone number': 'Bitte geben Sie Ihre Telefonnummer ein',
      'We will send you a one-time verification code': 'Wir senden Ihnen einen einmaligen Verifizierungscode',
      'Country code': 'Land (Region) Code',
      Number: 'Nummer',
      'Get verification code': 'Verifizierungscode erhalten',
      'Thanks for your interest in V-Ray and Chaos. It seems that you’ve already received a free trial or your license includes access to all Chaos products.':
        'Vielen Dank für Ihr Interesse an V-Ray und Chaos. Es scheint, dass Sie bereits eine kostenlose Testversion erhalten haben oder Ihre Lizenz den Zugang zu allen Chaos-Produkten beinhaltet.',
      'You can check your active and expired licenses in your account.':
        'Sie können Ihre aktiven und abgelaufenen Lizenzen in <1>Ihrem Konto</1> überprüfen',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        'Sie möchten eine neue Lizenz erwerben? Schauen Sie sich unsere <1>Preisliste</1> an und finden Sie, was Sie brauchen.',
      'Need help? Visit our help center for quick answers.':
        'Sie brauchen Hilfe? Besuchen Sie unser <1>Hilfe-Center</1> für schnelle Antworten.',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        'Sie haben Chaos Cloud bereits getestet. Sie können Ihrem Konto entweder über <2>unsere Website</2>oder Ihren <5>bevorzugten Wiederverkäufer</5> weitere Credits hinzufügen.',
      'Please select': 'Bitte auswählen',
      'We’d love to have you back!': 'Wir würden uns freuen, Sie wieder zu sehen!',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        'Sie haben bereits eine {{betaProduct}}-Lizenz. Ihre Lizenz gilt für die gesamte Dauer der offiziellen Betaphase.',
      'If you have any questions, visit our forum.': 'Wenn Sie Fragen haben, <1>besuchen Sie unser Forum.</1>',
      'Thank you for joining the {{betaProduct}}.':
        'Vielen Dank, dass Sie sich für das {{betaProduct}} entschieden haben.',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        'Ihre Beta-Lizenz ist jetzt aktiv. Jetzt können Sie alle neu eingeführten Funktionen und Verbesserungen testen. Ihre Lizenz gilt für die gesamte Dauer der offiziellen Betatestphase. Wir haben eine E-Mail mit weiteren Informationen an folgende Adresse gesendet:',
      'Download beta': 'Beta-Version herunterladen',
      'Start rendering': 'Rendering starten',
      // Chaos Vantage
      'Download product': 'Produkt herunterladen',
      'Experience real-time like never before.': 'Erleben Sie Real-Time wie nie zuvor.',
      'Your product is now ready for download.': 'Ihr Produkt steht nun zum Download bereit.',
      'This promotional license is free of charge and valid until December 31, 2022.':
        'Diese kostenlose Sonderlizenz ist bis zum <strong>31. Dezember 2022</strong> gültig.',
      'If you’d like to download more than one license, please contact our friendly support team.':
        'Wenn Sie mehr als eine Lizenz herunterladen möchten, <2>wenden Sie sich an unser freundliches Support-Team.</2>',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray Personal Learning Edition (PLE) für Maya',
      'Fill in your field of teaching': 'Geben Sie Ihr Unterrichtsfach an',
      'Which university/training center do you teach at?': 'An welcher Hochschule/Ausbildungsstätte unterrichten Sie?',
      'Your phone number': 'Ihre Telefonnummer',
      'Change occupation': 'Beruf ändern',
      'Fill in your industry': 'Geben Sie Ihre Branche an',
      'Fill in your field of study': 'Geben Sie Ihr Studienfach an',
      'What is your university’s/training center’s name?': 'Wie heißt Ihre Hochschule/Ihre Ausbildungsstätte?',
      '(optional)': '(optional)',
      'How often do you typically use V-Ray PLE?': 'Wie oft verwenden Sie V-Ray PLE in der Regel?',
      'Do you use any other DCCs apart from Autodesk Maya?': 'Verwenden Sie außer Autodesk Maya noch andere DCCs?',
      Yes: 'Ja',
      No: 'Nein',
      'Please specify which other DCCs you’re using.': 'Geben Sie an, welche anderen DCCs Sie verwenden.',
      'Do you use any other renderers?': 'Verwenden Sie andere Renderer?',
      'Please specify which other renderer(s) you’re using.': 'Geben Sie an, welche anderen Renderer Sie verwenden.',
      'How long do you plan to use V-Ray PLE?': 'Wie lange haben Sie vor, V-Ray PLE zu verwenden?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'Teilen Sie uns Ihr Feedback zu Ihren Erfahrungen mit V-Ray PLE und den verfügbaren Lernressourcen mit.',
      'Share your work done with V-Ray PLE with us.': 'Teilen Sie Ihre mit V-Ray PLE erstellten Arbeiten mit uns.',
      Submit: 'Senden',
      'Please confirm that your details are up-to-date.':
        'Bestätigen Sie, dass Ihre persönlichen Daten noch aktuell sind.',
      'Tell us more about your experience with PLE.': 'Erzählen Sie uns mehr über Ihre Erfahrungen mit PLE.',
      'Customize your first steps into V-Ray for Maya.': 'Passen Sie Ihre ersten Schritte in V-Ray für Maya an.',
      'You already have an active V-Ray PLE for Maya license.':
        'Sie haben bereits eine aktive Lizenz für V-Ray PLE für Maya.',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        'Ihre Lizenz ist 90 Tage lang gültig und kann innerhalb von 7 Tagen vor Ablauf verlängert werden.',
      'If it expires, you can request a new one by completing the form again.':
        'Wenn die Lizenz abläuft, können Sie eine neue beantragen, indem Sie das Formular erneut ausfüllen.',
      'Follow the steps here': 'Befolgen Sie die folgenden Anweisungen',
      'Thank you': 'Vielen Dank',
      'Your license is now active, and will be available for the': 'Ihre Lizenz ist jetzt aktiv und wird für die',
      'next 90 days': 'nächsten 90 Tage verfügbar sein',
      Download: 'Download',
      Animation: 'Animation',
      Advertising: 'Werbung',
      'Education / Academia': 'Bildungs-/Hochschulwesen',
      Gaming: 'Gaming',
      'Product Design': 'Produktdesign',
      'Visualization Specialist / 3D Artist': 'Visualisierungsspezialist/3D-Künstler',
      'Technical Director': 'Technical Director',
      'CG / VFX Supervisor': 'CG / VFX Supervisor',
      'I didn’t have time to test it': 'Ich hatte keine Zeit, die Anwendung zu testen',
      'I want to keep learning': 'Ich möchte weiter lernen',
      'I have a personal/university project to complete': 'Ich muss ein privates/universitäres Projekt fertigstellen',
      'I am attending a rendering class/course': 'Ich besuche einen Rendering-Kurs',
      'Every day': 'Täglich',
      'Every week': 'Wöchentlich',
      'Once a month': 'Monatlich',
      'Occasionally to complete a project/class': 'Gelegentlich für ein Projekt/einen Kurs',
      'One month': 'Ein Monat',
      'Three months': 'Drei Monate',
      'Six months': 'Sechs Monate',
      'As long as I need to learn V-Ray': 'Bis ich V-Ray gelernt habe',
      'Not sure, just testing for now': 'Ich bin nicht sicher, ich will erst einmal nur testen',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        'Indem ich fortfahre, akzeptiere ich die <privacy>Datenschutzrichtlinie</privacy>, die <terms>Nutzungsbedingungen</terms> und die <eula>EULA</eula>',
      'What is your field of teaching?': 'In welchem Bereich unterrichten Sie?',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        'Was ist der Hauptgrund für die Erneuerung Ihrer Lizenz für V-Ray PLE?',
      Confirm: 'Bestätigen',
      "Let's customize your trial experience.": 'Lassen Sie uns Ihre Testversion individuell gestalten.',
      'Tell us a bit more about yourself.': 'Wir möchten Sie näher kennenlernen.',
      'Field of practice': 'Tätigkeitsbereich',
      Role: 'Rolle',
      'Organization name': 'Name des Unternehmens',
      'Do you have colleagues interested in licenses as well?':
        'Haben Sie Kolleginnen und Kollegen, die ebenfalls an Lizenzen interessiert sind?',
      "It's just me": 'Nur ich',
      "We're happy to have you onboard!": 'Wir freuen uns, dass Sie dabei sind!',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        'Jetzt können Sie alles erkunden, was in Ihrer kostenlosen {{duration}}-tägigen Testversion enthalten ist.',
      'Download your trial': 'Ihre Testversion herunterladen',
      Continue: 'Fortfahren',
      'Start your free trial': 'Kostenlose Testversion starten',
      Back: 'Zurück',
      'Welcome back!': 'Herzlich willkommen!',
      'Manage your licenses': 'Lizenzen verwalten',
      'Discover plans & pricing': 'Pläne und Preise entdecken',
      'Need a hand?': 'Benötigen Sie Hilfe?',
      'personalized-assistance': 'Unser Vertriebsteam berät <assistance>Sie gerne persönlich</assistance>.',
      'Your trial download is just moments away.':
        'Der Download Ihrer Testversion ist nur wenige Augenblicke entfernt.',
      'Great to see your interest in Chaos products.': 'Vielen Dank für Ihr Interesse an Chaos-Produkten.',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        'Wenn Sie gerade mit einer Testversion arbeiten, schon einmal eine getestet haben oder bereits Teil unserer Community sind, sind wir gerne für Sie da.',
      'Please enter your phone number. We will send you a one-time verification code':
        'Geben Sie Ihre Mobilnummer ein. Wir senden Ihnen einen einmaligen Verifizierungscode.',
      'Phone number': 'Mobilnummer',
      'You will get exclusive updates and training materials':
        'Sie werden exklusive Updates und Schulungsmaterialien erhalten',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  fr: {
    translation: {
      lang: 'fr',
      'Your trial is a step away': 'Votre version d’essai est à portée de clic',
      business: 'Professionnel',
      student: 'Étudiant',
      educator: 'Enseignant',
      individual: 'Particulier',
      'Verify your account.': 'Vérifiez votre compte.',
      'Tool of choice': 'Outil de prédilection',
      'Which best describes your role?': 'Quel énoncé décrit le mieux votre rôle ?',
      'What is your role?': 'Quel est votre rôle ?',
      Administrator: 'Administrateur',
      'Application Engineer': 'Ingénieur d’application',
      Architect: 'Architecte',
      'BIM Specialist': 'Spécialiste BIM',
      'Business Owner': 'Chef d’entreprise',
      'C-Suite | Managing Partner': 'Cadre dirigeant | Associé',
      'Construction Specialist': 'Spécialiste de la construction',
      Consultant: 'Consultant',
      'Designer - Interior': 'Concepteur - Intérieur',
      'Designer - Product': 'Concepteur - Produit',
      'Director / Principal / Team Leader': 'Directeur / Directeur principal / Responsable d’équipe',
      'Educator / Professor / Teacher': 'Formateur / Professeur / Enseignant',
      'Procurement / Admin': 'Achats / Administration',
      Engineer: 'Ingénieur',
      'Operations Manager': 'Responsable opérationnel',
      'Marketing Specialist': 'Spécialistes marketing',
      'Project Manager': 'Responsable projet',
      Student: 'Étudiant',
      'Visualization Specialist | 3D Artist': 'Spécialiste visualisation | Artiste 3D',
      Other: 'Autre',
      'What industry are you in?': 'Dans quel secteur d’activité travaillez-vous?',
      'What is your field of practice?': 'Quel est votre domaine d’activité?',
      "What is your organization's name?": 'Quel est le nom de votre organisation?',
      'What is your field of study?': 'Dans quel domaine étudiez-vous?',
      "What is your university's name?": 'Quel est le nom de votre université?',
      'Media & Entertainment': 'Médias et divertissement',
      Architecture: 'Architecture',
      Automotive: 'Automobile',
      'Building operations and management': 'Exploitation et gestion de bâtiments',
      Construction: 'Construction',
      Consulting: 'Conseils',
      'Education/Academia': 'Formation/Enseignement',
      Engineering: 'Ingénierie',
      'Interior design': 'Architecture d’intérieur',
      'Landscape architecture': 'Architecture du paysage',
      'Product design': 'Conception de produits',
      'Real estate & Development': 'Immobilier et développement',
      'Urban planning': 'Urbanisme',
      'Company name': 'Nom de l’entreprise',
      'Educational institution': 'Établissement d’enseignement',
      'What is your primary tool of choice?': 'Quel est votre outil de prédilection?',
      'What operating system will you use?': "Quel système d'exploitation utiliserez-vous?",
      'Host platform': 'Plateforme hôte',
      'What is your current occupation?': 'Quelle est votre profession actuelle?',
      professional: 'professionnel',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        'Vos crédits Chaos Cloud sont désormais actifs et seront disponibles pendant les 30 prochains jours.',
      "We've sent an email with further information to":
        'Nous avons envoyé un e-mail avec de plus amples informations à l’adresse',
      "You'll need to download all the products you'd like to try individually.":
        'Vous devrez télécharger individuellement tous les produits que vous souhaitez essayer.',
      'Find out': 'Découvrez',
      'how to install': 'comment installer',
      'This helps us confirm your identity and secure your account':
        'Cela nous permet de confirmer votre identité et de sécuriser votre compte.',
      "We've just sent a text message with a 6-digit verification code to":
        'Nous venons d’envoyer un SMS avec un code de vérification à 6 chiffres au',
      'Verification code': 'Code de vérification',
      'Verify account': 'Vérifier le compte',
      "Didn't get the code?": 'Vous n’avez pas reçu de code ?',
      'Note that this can take a few minutes': 'Sachez que cela peut prendre plusieurs minutes',
      'Resend the code': 'Renvoyer le code',
      'Please enter your phone number': 'Veuillez saisir votre numéro de téléphone',
      'We will send you a one-time verification code': 'Nous vous enverrons un code de vérification unique',
      'Country code': 'Indicatif du pays',
      Number: 'Numéro',
      'Get verification code': 'Recevoir un code de vérification',
      'Thanks for your interest in V-Ray and Chaos. It seems that you’ve already received a free trial or your license includes access to all Chaos products.':
        'Merci de votre intérêt pour V-Ray et Chaos! Il semble que vous ayez déjà profité d’un essai gratuit ou que votre licence comprenne déjà l’accès à tous les produits Chaos.',
      'You can check your active and expired licenses in your account.':
        'Vous pouvez vérifier vos licences actives et expirées dans <1>votre compte</1>.',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        'Vous souhaitez acheter une nouvelle licence? Consultez notre <1>page des tarifs</1> et trouvez ce qu’il vous faut.',
      'Need help? Visit our help center for quick answers.':
        'Besoin d’aide? Visitez <1>notre centre</1> d’aide pour obtenir des réponses rapides.',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        'Vous avez déjà testé Chaos Cloud. Vous pouvez ajouter des crédits supplémentaires à votre compte via <2>notre site Web</2> ou par l’intermédiaire de votre <5>revendeur préféré.</5>',
      'Please select': 'Veuillez effectuer une sélection',
      'We’d love to have you back!': 'Nous sommes ravis de vous revoir!',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        'Vous disposez déjà d’une licence {{betaProduct}}. Votre licence sera valide pendant toute la période de test bêta officielle.',
      'If you have any questions, visit our forum.': 'Si vous avez des questions, <1>consultez notre forum.</1>',
      'Thank you for joining the {{betaProduct}}.': 'Merci d’avoir rejoint la communauté {{betaProduct}}.',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        'Votre licence bêta est désormais active. Vous pouvez maintenant commencer à tester toutes les nouvelles fonctionnalités et améliorations que nous avons introduites. Votre licence sera valide pendant toute la période de test bêta officielle. Nous avons envoyé un e-mail avec de plus amples informations à l’adresse',
      'Download beta': 'Télécharger la version bêta',
      'Start rendering': 'Commencer la création de rendu',
      // Chaos Vantage
      'Download product': 'Télécharger le produit',
      'Experience real-time like never before.': 'Vivez une expérience de rendu en temps réel inédite.',
      'Your product is now ready for download.': 'Votre produit est maintenant prêt pour le téléchargement.',
      'This promotional license is free of charge and valid until December 31, 2022.':
        'Cette licence promotionnelle est gratuite et valide jusqu’au 31 décembre 2022.',
      'If you’d like to download more than one license, please contact our friendly support team.':
        'Si vous souhaitez télécharger plusieurs licences, veuillez contacter notre sympathique <2>équipe d’assistance technique.</2>',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray Personal Learning Edition (PLE) for Maya',
      'Fill in your field of teaching': 'Indiquez votre domaine d’enseignement',
      'Which university/training center do you teach at?':
        'Dans quel établissement universitaire/centre de formation enseignez-vous?',
      'Your phone number': 'Votre numéro de téléphone',
      'Change occupation': 'Modifier la profession',
      'Fill in your industry': 'Indiquez votre secteur d’activité',
      'Fill in your field of study': 'Indiquez votre domaine d’étude',
      'What is your university’s/training center’s name?':
        'Quel est le nom de votre établissement universitaire/centre de formation?',
      '(optional)': '(facultatif)',
      'How often do you typically use V-Ray PLE?': 'À quelle fréquence utilisez-vous V-Ray PLE en règle générale?',
      'Do you use any other DCCs apart from Autodesk Maya?':
        'Utilisez-vous d’autres logiciels DCC en dehors d’Autodesk Maya?',
      Yes: 'Oui',
      No: 'Non',
      'Please specify which other DCCs you’re using.': 'Veuillez spécifier les autres logiciels DCC que vous utilisez.',
      'Do you use any other renderers?': 'Utilisez-vous d’autres modules de rendu ?',
      'Please specify which other renderer(s) you’re using.':
        'Veuillez spécifier les autres logiciels de rendu que vous utilisez.',
      'How long do you plan to use V-Ray PLE?': 'Combien de temps prévoyez-vous d’utiliser V-Ray PLE ?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'N’hésitez pas à nous faire part de vos commentaires sur votre expérience de V-Ray PLE et des ressources d’apprentissage disponibles.',
      'Share your work done with V-Ray PLE with us.': 'Partagez avec nous vos projets réalisés avec V-Ray PLE.',
      Submit: 'Valider',
      'Please confirm that your details are up-to-date.': 'Veuillez confirmer que vos informations sont à jour.',
      'Tell us more about your experience with PLE.': 'Dites-nous en un peu plus sur votre expérience de PLE.',
      'Customize your first steps into V-Ray for Maya.': 'Personnalisez vos premiers pas avec V-Ray for Maya.',
      'You already have an active V-Ray PLE for Maya license.': 'Vous avez déjà une licence V-Ray PLE for Maya active.',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        'Votre licence est valide 90 jours et peut être renouvelée dans les 7 jours précédant son expiration.',
      'If it expires, you can request a new one by completing the form again.':
        'Si elle arrive à expiration, vous pouvez en demander une nouvelle en complétant de nouveau le formulaire.',
      'Follow the steps here': 'Suivez les étapes ci-dessous',
      'Thank you': 'Merci',
      'Your license is now active, and will be available for the':
        'Votre licence est maintenant active et sera disponible pendant les',
      'next 90 days': '90 prochains jours',
      Download: 'Télécharger',
      Animation: 'Animation',
      Advertising: 'Publicité',
      'Education / Academia': 'Enseignement / Formation',
      Gaming: 'Jeux vidéo',
      'Product Design': 'Conception de produits',
      'Visualization Specialist / 3D Artist': 'Spécialiste visualisation / Artiste 3D',
      'Technical Director': 'Directeur technique',
      'CG / VFX Supervisor': 'Superviseur CG / VFX',
      'I didn’t have time to test it': 'Je n’ai pas le temps de l’essayer',
      'I want to keep learning': 'Je souhaite poursuivre mon apprentissage',
      'I have a personal/university project to complete': 'J’ai un projet personnel/universitaire à réaliser',
      'I am attending a rendering class/course': 'Je participe à un cours/une formation sur la création de rendu',
      'Every day': 'Tous les jours',
      'Every week': 'Toutes les semaines',
      'Once a month': 'Une fois par mois',
      'Occasionally to complete a project/class': 'Occasionnellement pour terminer un projet/un cours',
      'One month': 'Un mois',
      'Three months': 'Trois mois',
      'Six months': 'Six mois',
      'As long as I need to learn V-Ray': 'Aussi longtemps que j’aurai besoin de me former sur V-Ray',
      'Not sure, just testing for now': 'Je ne sais pas encore, je fais juste un essai pour l’instant',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        'En continuant, j’accepte la <privacy>politique de confidentialité</privacy>, <terms>les conditions d’utilisation</terms> et <eula>le CLUF</eula>',
      'What is your field of teaching?': 'Dans quel domaine enseignez-vous ?',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        'Quelle est la raison principale pour laquelle vous renouvelez votre licence V-Ray PLE ?',
      Confirm: 'Confirmer',
      "Let's customize your trial experience.": 'Personnalisons votre expérience de la version d’essai.',
      'Tell us a bit more about yourself.': 'Dites-nous en un peu plus sur vous.',
      'Field of practice': 'Domaine d’activité',
      Role: 'Rôle',
      'Organization name': 'Nom de l’organisation',
      'Do you have colleagues interested in licenses as well?':
        'Avez-vous des collègues également intéressés par des licences ?',
      "It's just me": 'Moi uniquement',
      "We're happy to have you onboard!": 'Nous sommes ravis de vous compter parmi nous !',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        'Vous pouvez maintenant découvrir tout ce qui est inclus dans votre version d’essai gratuite de {{duration}} jours.',
      'Download your trial': 'Télécharger votre version d’essai',
      Continue: 'Continuer',
      'Start your free trial': 'Commencer votre essai gratuit',
      Back: 'Retour',
      'Welcome back!': 'Bon retour parmi nous !',
      'Manage your licenses': 'Gérer vos licences',
      'Discover plans & pricing': 'Voir les formules et les tarifs',
      'Need a hand?': 'Besoin d’aide ?',
      'personalized-assistance':
        'Nos équipes commerciales sont là pour vous offrir une <assistance>assistance personnalisée.</assistance>',
      'Your trial download is just moments away.': 'Le téléchargement de votre version d’essai commencera sous peu.',
      'Great to see your interest in Chaos products.': 'Merci de l’intérêt que vous portez aux produits Chaos.',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        'Si vous utilisez actuellement une version d’essai, si vous avez déjà essayé nos produits ou si vous faites déjà partie de notre communauté, nous sommes là pour vous aider.',
      'Please enter your phone number. We will send you a one-time verification code':
        'Veuillez saisir votre numéro de téléphone. Nous vous enverrons un code de vérification unique',
      'Phone number': 'Numéro de téléphone',
      'You will get exclusive updates and training materials':
        'Vous recevrez des mises à jour exclusives et du matériel de formation',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
  it: {
    translation: {
      lang: 'it',
      'Your trial is a step away': 'La tua versione di prova è a portata di clic',
      business: 'Azienda',
      student: 'Studente',
      educator: 'Istruttore',
      individual: 'Persona singola',
      'Verify your account.': 'Verifica il tuo account.',
      'Tool of choice': 'Strumento scelto',
      'Which best describes your role?': 'Quale descrive meglio il tuo ruolo?',
      'What is your role?': 'Qual è il tuo ruolo?',
      Administrator: 'Amministratore',
      'Application Engineer': 'Ingegnere applicativo',
      Architect: 'Architetto',
      'BIM Specialist': 'Specialista BIM',
      'Business Owner': 'Proprietario d’azienda',
      'C-Suite | Managing Partner': 'Dirigente di alto livello | Direttore generale',
      'Construction Specialist': 'Specialista delle costruzioni',
      Consultant: 'Consulente',
      'Designer - Interior': 'Designer - Interni',
      'Designer - Product': 'Designer - Prodotti',
      'Director / Principal / Team Leader': 'Dirigente / Titolare / Team Leader',
      'Educator / Professor / Teacher': 'Istruttore / Professore / Insegnante',
      'Procurement / Admin': 'Approvvigionamento / Amministrazione',
      Engineer: 'Ingegnere',
      'Operations Manager': 'Responsabile delle operazioni',
      'Marketing Specialist': 'Specialista di marketing',
      'Project Manager': 'Project Manager',
      Student: 'Studente',
      'Visualization Specialist | 3D Artist': 'Specialista della visualizzazione | Artista 3D',
      Other: 'Altro',
      'What industry are you in?': 'Qual è il tuo settore di pertinenza?',
      'What is your field of practice?': 'Qual è il tuo ambito di lavoro?',
      "What is your organization's name?": 'Come si chiama la tua organizzazione?',
      'What is your field of study?': 'Qual è il tuo campo di studi?',
      "What is your university's name?": 'Come si chiama la tua università?',
      'Media & Entertainment': 'Media e intrattenimento',
      Architecture: 'Architettura',
      Automotive: 'Automotive',
      'Building operations and management': 'Gestione e direzione di edifici',
      Construction: 'Costruzioni',
      Consulting: 'Consulenza',
      'Education/Academia': 'Istruzione/Università',
      Engineering: 'Ingegneria',
      'Interior design': 'Progettazione di interni',
      'Landscape architecture': 'Architettura del paesaggio',
      'Product design': 'Progettazione di prodotto',
      'Real estate & Development': 'Settore immobiliare e sviluppo',
      'Urban planning': 'Pianificazione urbana',
      'Company name': 'Nome dell’azienda',
      'Educational institution': 'Enti formativi',
      'What is your primary tool of choice?': 'Qual è il tuo strumento principale?',
      'What operating system will you use?': 'Che sistema operativo utilizzerai?',
      'Host platform': 'Piattaforma host',
      'What is your current occupation?': 'Qual è la tua occupazione attuale?',
      professional: 'professionale',
      'Your Chaos Cloud credits are now active and will be available for the next 30 days.':
        'I tuoi crediti di Chaos Cloud sono attivi e saranno disponibili per i prossimi 30 giorni.',
      "We've sent an email with further information to": 'Abbiamo inviato un’e-mail con maggiori informazioni a',
      "You'll need to download all the products you'd like to try individually.":
        'Dovrai scaricare tutti i prodotti che desideri provare uno ad uno.',
      'Find out': 'Scopri',
      'how to install': 'come installare',
      'This helps us confirm your identity and secure your account':
        'Questo ci permette di confermare la tua identità e proteggere il tuo account',
      "We've just sent a text message with a 6-digit verification code to":
        'Abbiamo appena inviato un SMS con un codice di verifica di 6 cifre a',
      'Verification code': 'Codice di verifica',
      'Verify account': 'Verifica il tuo account',
      "Didn't get the code?": 'Non hai ricevuto il codice?',
      'Note that this can take a few minutes': 'Potrebbero volerci alcuni minuti',
      'Resend the code': 'Rinvia il codice',
      'Please enter your phone number': 'Inserisci il tuo numero di telefono',
      'We will send you a one-time verification code': 'Ti invieremo un codice di verifica temporaneo',
      'Country code': 'Codice nazionale',
      Number: 'Numero',
      'Get verification code': 'Ottieni codice di verifica',
      'Thanks for your interest in V-Ray and Chaos. It seems that you’ve already received a free trial or your license includes access to all Chaos products.':
        'Grazie per il tuo interesse per V-Ray e Chaos. Sembra che tu abbia già usufruito della prova gratuita oppure la tua licenza include l’accesso a tutti i prodotti di Chaos.',
      'You can check your active and expired licenses in your account.':
        'Puoi verificare le licenze attive e scadute nel <1>tuo account.</1>',
      'Want to buy a new license? Visit our pricing page and find what you need.':
        'Vuoi acquistare una nuova licenza? Visita la nostra <1>pagina dei prezzi</1> e scopri cosa fa al caso tuo.',
      'Need help? Visit our help center for quick answers.':
        'Hai bisogno di aiuto? Visita la nostra <1>Guida rapida</1> per ottenere delle risposte rapide.',
      'You have already tested Chaos Cloud. You can add more credits to your account via our website or your preferred reseller.':
        'Hai già provato Chaos Cloud. Puoi aggiungere altri crediti al tuo account tramite il nostro <2>sito Web</2> oppure il tuo <5>rivenditore di fiducia.</5>',
      'Please select': 'Seleziona',
      'We’d love to have you back!': 'Ci piacerebbe riaverti con noi!',
      // Beta
      'You already have a {{betaProduct}} license. Your license will be valid throughout the official beta period.':
        'Possiedi già una licenza di {{betaProduct}}. La tua licenza sarà valida per tutta la fase beta ufficiale.',
      'If you have any questions, visit our forum.': 'Per qualsiasi domanda visita il <1>nostro forum.</1>',
      'Thank you for joining the {{betaProduct}}.': 'Grazie per aver scelto {{betaProduct}}.',
      "Your beta license is now active. Now, you can start testing all the new features and improvements that we’ve introduced. Your license will be valid throughout the official beta testing period. We've sent an email with further information to":
        'La tua licenza beta ora è attiva. Ora puoi iniziare a testare tutte le nuove funzionalità e i miglioramenti che abbiamo introdotto. La tua licenza sarà valida per tutta la fase ufficiale di beta testing. Abbiamo inviato un’e-mail con maggiori informazioni a',
      'Download beta': 'Scarica la beta',
      'Start rendering': 'Inizia a creare rendering',
      // Chaos Vantage
      'Download product': 'Scarica il prodotto',
      'Experience real-time like never before.': 'Scopri il rendering in tempo reale come non l’hai mai visto.',
      'Your product is now ready for download.': 'Il prodotto è pronto per il download.',
      'This promotional license is free of charge and valid until December 31, 2022.':
        'Questa licenza promozionale è gratis e valida fino al 31 dicembre 2022.',
      'If you’d like to download more than one license, please contact our friendly support team.':
        'Qualora volessi scaricare più di una licenza, ti invitiamo a <2>contattare il nostro team di servizio.</2>',
      // Maya PLE
      'V-Ray Personal Learning Edition (PLE) for Maya': 'V-Ray Personal Learning Edition (PLE) per Maya',
      'Fill in your field of teaching': 'Inserisci l’ambito di insegnamento',
      'Which university/training center do you teach at?': 'In quale università/centro formativo insegni?',
      'Your phone number': 'Il tuo numero di telefono',
      'Change occupation': 'Modifica l’occupazione',
      'Fill in your industry': 'Inserisci il settore',
      'Fill in your field of study': 'Inserisci il campo di studi',
      'What is your university’s/training center’s name?': 'Come si chiama la tua università/il tuo centro formativo?',
      '(optional)': '(opzionale)',
      'How often do you typically use V-Ray PLE?': 'Di solito con che frequenza usi V-Ray PLE?',
      'Do you use any other DCCs apart from Autodesk Maya?': 'Usi altre soluzioni DCC oltre ad Autodesk Maya?',
      Yes: 'Sì',
      No: 'No',
      'Please specify which other DCCs you’re using.': 'Specifica quali altre soluzioni DCC usi.',
      'Do you use any other renderers?': 'Usi altri renderer?',
      'Please specify which other renderer(s) you’re using.': 'Specifica quali altri renderer usi.',
      'How long do you plan to use V-Ray PLE?': 'Quanto prevedi di usare V-Ray PLE?',
      'Please share any feedback about your experience with V-Ray PLE and the available learning resources.':
        'Ti invitiamo a condividere il tuo feedback sull’esperienza con V-Ray PLE e sulle risorse per la formazione disponibili.',
      'Share your work done with V-Ray PLE with us.': 'Condividi con noi il tuo lavoro svolto con V-Ray PLE.',
      Submit: 'Invia',
      'Please confirm that your details are up-to-date.': 'Conferma che le tue informazioni sono aggiornate.',
      'Tell us more about your experience with PLE.': 'Raccontaci qualcosa in più sulla tua esperienza con PLE.',
      'Customize your first steps into V-Ray for Maya.': 'Personalizza i tuoi primi passi con V-Ray per Maya.',
      'You already have an active V-Ray PLE for Maya license.':
        'Possiedi già una licenza attiva di V-Ray PLE per Maya.',
      'Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.':
        'La licenza è valida per 90 giorni e può essere rinnovata fino a 7 giorni prima della scadenza.',
      'If it expires, you can request a new one by completing the form again.':
        'Una volta scaduta, puoi richiederne una nuova compilando nuovamente il modulo.',
      'Follow the steps here': 'Segui i passaggi qui',
      'Thank you': 'Grazie',
      'Your license is now active, and will be available for the': 'La tua licenza è attiva e sarà disponibile per i',
      'next 90 days': 'prossimi 90 giorni',
      Download: 'Scarica',
      Animation: 'Animazione',
      Advertising: 'Pubblicità',
      'Education / Academia': 'Istruzione/Università',
      Gaming: 'Gaming',
      'Product Design': 'Progettazione di prodotto',
      'Visualization Specialist / 3D Artist': 'Specialista della visualizzazione/Artista 3D',
      'Technical Director': 'Direttore tecnico',
      'CG / VFX Supervisor': 'CG/VFX Supervisor',
      'I didn’t have time to test it': 'Non ho avuto il tempo di provarlo',
      'I want to keep learning': 'Voglio proseguire la mia formazione',
      'I have a personal/university project to complete': 'Ho un progetto personale/universitario da completare',
      'I am attending a rendering class/course': 'Sto frequentando una lezione/un corso di rendering',
      'Every day': 'Ogni giorno',
      'Every week': 'Ogni settimana',
      'Once a month': 'Una volta al mese',
      'Occasionally to complete a project/class': 'Occasionalmente per completare un progetto/corso',
      'One month': 'Un mese',
      'Three months': 'Tre mesi',
      'Six months': 'Sei mesi',
      'As long as I need to learn V-Ray': 'Quanto serve per imparare a usare V-Ray',
      'Not sure, just testing for now': 'Non so, per ora lo sto solo provando',
      'By continuing, I accept the Privacy Policy, Terms of Use and EULA':
        'Proseguendo, accetto l’informativa <privacy>sulla privacy</privacy>, i <terms>termini e le condizioni</terms> d’uso e il <eula>CLUF</eula>',
      'What is your field of teaching?': 'Qual è il tuo ambito di insegnamento?',
      'What’s the primary reason for renewing your V-Ray PLE license?':
        'Qual è il motivo principale del rinnovo della tua licenza di V-Ray PLE?',
      Confirm: 'Conferma',
      "Let's customize your trial experience.": 'Personalizza tua esperienza con la versione di prova.',
      'Tell us a bit more about yourself.': 'Raccontaci qualcosa in più di te.',
      'Field of practice': 'Ambito di lavoro',
      Role: 'Ruolo',
      'Organization name': 'Nome dell’organizzazione',
      'Do you have colleagues interested in licenses as well?': 'Conosci dei colleghi interessati alle licenze?',
      "It's just me": 'Solo io',
      "We're happy to have you onboard!": 'È fantastico averti tra noi!',
      'Now you can explore everything included in your free {{duration}}-day trial.':
        'Ora puoi scoprire tutto ciò che è incluso nella tua versione di prova gratis di {{duration}} giorni.',
      'Download your trial': 'Scarica la prova',
      Continue: 'Continua',
      'Start your free trial': 'Inizia la tua prova gratuita',
      Back: 'Indietro',
      'Welcome back!': 'È bello rivederti!',
      'Manage your licenses': 'Gestisci le tue licenze',
      'Discover plans & pricing': 'Visualizza piani e prezzi',
      'Need a hand?': 'Hai bisogno di aiuto?',
      'personalized-assistance':
        'Il nostro team addetto alle vendite ti fornirà <assistance>un’assistenza su misura.</assistance>',
      'Your trial download is just moments away.': 'Ancora pochi passi al download della versione di prova.',
      'Great to see your interest in Chaos products.': 'Siamo felici per l’interesse dimostrato per i prodotti Chaos.',
      "If you're currently on a trial, have had one before, or are already part of our community, we're here for you.":
        'Se sei nel periodo di prova, ne hai già svolto uno o fai già parte della nostra community: siamo qui per te.',
      'Please enter your phone number. We will send you a one-time verification code':
        'Inserisci il tuo numero di telefono. Ti invieremo un codice di verifica temporaneo',
      'Phone number': 'Numero di telefono',
      'You will get exclusive updates and training materials':
        'Riceverai aggiornamenti esclusivi e materiale formativo',
      'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.':
        'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
      'Learn more': 'Learn more',
    },
  },
};
