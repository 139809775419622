import {
  BETA_BLENDER_SELECT,
  BETA_BLENDER_CLEAR,
  BETA_BLENDER_SET_LOADING,
  BETA_BLENDER_ERROR,
  BETA_BLENDER_SET_STEP,
} from './constants';

const initialState = {
  error: null,
  loading: false,
  step: 0,
  selected: null,
};

export default function beta(state = initialState, payload) {
  switch (payload.type) {
    case BETA_BLENDER_SET_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case BETA_BLENDER_SELECT:
      return {
        ...state,
        selected: { ...state.selected, ...payload.data },
      };
    case BETA_BLENDER_CLEAR:
      return {
        ...state,
        selected: {},
      };
    case BETA_BLENDER_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case BETA_BLENDER_SET_STEP:
      return {
        ...state,
        step: payload.step,
      };
    default:
      return state;
  }
}
