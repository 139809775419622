import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { CustomStepper } from '../FreeTrial/Wiz';

const Success = ({ t }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="maya-ple">
      <Helmet>
        <title>V-Ray for Blender beta</title>
        <meta name="description" content="V-Ray for Blender beta" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('V-Ray for Blender beta')}</h3>
      </div>
      <div className="content free-trials maya-ple">
        <CustomStepper steps={Array(3).fill()} step={2} />
        <br />
        <br />
        <h4>{t('We’re happy to have you onboard!')}</h4>
        <br />
        <div className="content" style={{ lineHeight: '26px' }}>
          <div>
            <strong>{t('Your V-Ray for Blender beta license is now active.')}</strong>.
          </div>
          <div>
            You can start testing right away. Your license will be valid throughout the beta testing period.
            <br />
            <a href="https://forums.chaos.com/forum/v-ray-for-blender-beta" target="_blank" rel="noreferrer">
              Join us on the forum
            </a>{' '}
            and let us know what you think.
          </div>
          <br />
          <div className="centered">
            <button
              type="button"
              className="button"
              style={{ width: 'auto' }}
              onClick={() => {
                window.location.href = `${window.DOWNLOADS_URL}/products/VR072BSPBCL001`;
              }}
            >
              {t('Download beta')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Success);
