import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FREE_TRIAL_CONFIRM, FREE_TRIAL_RESEND, FREE_TRIAL_RESET } from './constants';

const VerifyConfirm = ({ freeTrial, onSubmit, onReset, onResend, t }) => {
  const [code, setCode] = useState('');

  return (
    <div className="step verify-confirm">
      <div className="subtitle">
        {t("We've just sent a text message with a 6-digit verification code to")}{' '}
        {maskNumber(window.localStorage.getItem('number'))}
      </div>
      <div className="phone-verification form-field-wrapper">
        <div className="item item-country">
          <label htmlFor="number">{t('Verification code')}</label>
          <input
            type="text"
            id="number"
            required
            placeholder="xxxxxxx"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      </div>
      <button
        type="button"
        className="button"
        onClick={() => onSubmit(code)}
        disabled={code.length < 6 || freeTrial.loading}
      >
        {t('Verify account')}
      </button>
      <br />
      <br />
      <br />
      <br />
      <h5>{t("Didn't get the code?")}</h5>
      <p>
        {t('Note that this can take a few minutes')}
        <br />
        <br />
        <a href="#" onClick={onResend}>
          {t('Resend the code')}
        </a>{' '}
        <span className="middot">&#8226;</span>{' '}
        <a href="#" onClick={onReset}>
          {t('Enter another number')}
        </a>
      </p>
    </div>
  );
};

const maskNumber = (number) => {
  return `*** *** ${number.slice(-4)}`;
};

export default connect(
  (state) => ({
    freeTrial: state.freeTrial,
  }),
  (dispatch) => ({
    onSubmit: (code) => dispatch({ type: FREE_TRIAL_CONFIRM, code }),
    onReset: () => dispatch({ type: FREE_TRIAL_RESET }),
    onResend: () => dispatch({ type: FREE_TRIAL_RESEND }),
  }),
)(withTranslation()(VerifyConfirm));
