import { BETA_SELECT, BETA_CLEAR, BETA_SET_LOADING, BETA_ERROR, BETA_SET_STEP } from './constants';

const initialState = {
  error: null,
  loading: false,
  step: 0,
  selected: null,
};

export default function beta(state = initialState, payload) {
  switch (payload.type) {
    case BETA_SET_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case BETA_SELECT:
      return {
        ...state,
        selected: { ...state.selected, ...payload.data },
      };
    case BETA_CLEAR:
      return {
        ...state,
        selected: {},
      };
    case BETA_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case BETA_SET_STEP:
      return {
        ...state,
        step: payload.step,
      };
    default:
      return state;
  }
}
