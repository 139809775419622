import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import '../../styles/globals.css';

import '../../styles/free_trials.sass';
import '../../styles/maya_ple.sass';

const ChaosLayout = ({ children, routing }) => {
  const isImpact = routing.params?.product === 'enscape-trial' && routing.params?.step === 'enscape-impact';
  const product = isImpact ? 'enscape-impact' : routing.params?.product || 'free-trial';
  const isWelcomeBack = routing.id === 'FREE_TRIAL_WELCOME_BACK';

  return (
    <div className="corona-demo trial">
      <Helmet>
        <title>Chaos Free Trial</title>
        <meta name="description" content="Chaos Free Trial" />
        <link href="/chaos-favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
      </Helmet>
      <div
        className="column image-cover"
        style={{
          backgroundImage: `url(/images/trial/trial-cover-${isWelcomeBack ? 'welcome-back' : product}.jpg?ver=1.2.1)`,
        }}
      >
        {!isWelcomeBack && (
          <div className="image-cover-logo">
            <img src={`/images/trial/logo-${product}.png?ver=1.2.1`} alt="logo" />
          </div>
        )}
        <a href={window.WEBSITE_URL} className="logo trial">
          <img src="/images/trial/trial-logo.svg" alt="trial" />
        </a>
      </div>
      <div className="column content">
        <div className="top-wrapper">
          <div className="mobile-logo">
            <a href={window.WEBSITE_URL} className="logo trial">
              <img src="/images/trial/trial-logo.svg" alt="trial" />
            </a>
          </div>
        </div>
        <div className="wrapper-shrink">{children}</div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    auth: state.auth,
    routing: state.routing,
    freeTrial: state.freeTrial,
  }),
  (dispatch) => ({
    onLangChange: (lang) => dispatch({ type: 'LANG#CHANGE', lang: lang.toLowerCase() }),
  }),
)(ChaosLayout);
