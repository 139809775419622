import createSagaMiddleware from 'redux-saga';
import { call, put, fork, spawn, take, takeLatest, cancelled, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { saga as routerSaga, navigate } from 'redux-saga-first-router';
import routesMap from './routes';
import { init } from './app/App/appSagas';

export const sagaMiddleware = createSagaMiddleware();
const browserHistory = createBrowserHistory();

// We use call(halt) to indicate that the saga is done,
// but we still dont want to terminate for example
// it needs to perform clean-up when it is canceled
// by a navigation event:
export function* halt() {
  yield take('__NA__');
}

// routerNavigateHandler handles each `router/NAVIGATE` action dispatched.
// `API_QUEUE#RESET` action is dispatched when saga is cancelled.
function* routerNavigateHandler(route) {
  if (!route.query) {
    window.scroll(0, 0);
  }
  try {
    yield call(halt);
  } finally {
    if (yield cancelled()) {
      yield put({ type: 'API_QUEUE#RESET' });
    }
  }
}

function* watchRouterNavigate() {
  yield takeLatest('router/NAVIGATE', routerNavigateHandler);
}

function* watchLanguageChange() {
  yield takeLatest('LANG#CHANGE', languageChange);
}

function* languageChange({ lang }) {
  const routing = yield select((state) => state.routing);
  yield put(navigate(routing.id, { ...routing.params, lang: lang !== 'en' ? lang : null }));
}

export function* rootSaga() {
  yield fork(watchRouterNavigate);
  yield fork(watchLanguageChange);
  yield call(init);
  yield spawn(routerSaga, routesMap, browserHistory);
}
