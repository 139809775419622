import React from 'react';

const Success = () => {
  return (
    <div className="success">
      <h3>Congratulations!</h3>
      <br />
      <br />
      <h4>Your Corona trial is now active.</h4>
      <br />
      <h4>You can close this window and enjoy getting to know Corona!</h4>
    </div>
  );
};

export default Success;
