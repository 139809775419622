import React from 'react';
import { withTranslation } from 'react-i18next';

const NotEligible = ({ t }) => {
  return (
    <div className="maya-ple">
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('V-Ray Personal Learning Edition (PLE) for Maya')}</h3>
      </div>
      <div className="content free-trials">
        <div className="content role-form">
          <div className="centered">{t('You already have an active V-Ray PLE for Maya license.')}</div>
          <br />
          <div className="centered">
            {t('Your license is valid for 90 days and can be renewed within the 7 days leading up to expiration.')}
          </div>
          <br />
          <div className="centered">{t('If it expires, you can request a new one by completing the form again.')}</div>
          <br />
          <div className="centered">
            <a href="https://docs.chaos.com/x/15T5Aw" target="_blank" rel="noreferrer">
              {t('Follow the steps here')} &gt;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NotEligible);
