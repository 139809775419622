import {
  CORONA_DEMO_ERROR,
  CORONA_DEMO_CLEAR,
  CORONA_DEMO_ORDER,
  CORONA_DEMO_SELECT,
  CORONA_DEMO_ELIGIBILITY,
  CORONA_DEMO_RESET,
  CORONA_DEMO_LOADING,
} from './constants';

const initialState = {
  error: null,
  loading: false,
  formIsVisible: true,
  selected: {},
  eligibility: null,
};

export default function coronaDemo(state = initialState, payload) {
  switch (payload.type) {
    case CORONA_DEMO_ERROR:
      return { ...state, error: payload.error, loading: false };
    case CORONA_DEMO_ORDER:
      return { ...state, loading: true, formIsVisible: false };
    case CORONA_DEMO_SELECT:
      return {
        ...state,
        selected: { ...state.selected, ...payload.data },
      };
    case CORONA_DEMO_CLEAR:
      return {
        ...state,
        selected: {},
      };
    case CORONA_DEMO_ELIGIBILITY:
      return { ...state, loading: false, eligibility: payload.data };
    case CORONA_DEMO_LOADING:
      return { ...state, loading: true };
    case CORONA_DEMO_RESET:
      return { ...state, eligibility: { ...state.eligibility, verification_status: 'initial' } };
    default:
      return state;
  }
}
