import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Loading from '../../App/Loading';
import { BETA_CREATE_ENVISION, BETA_SELECT } from '../constants';
import Privacy from '../../MayaPle/Privacy';
import EnvisionForm from './envisionForm';

const Envision = ({ loading, error, selected, onOccupationSelect, onSubmit, t }) => {
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    onOccupationSelect('individual');
  }, []);

  if (loading || !selected) {
    return <Loading />;
  }

  return (
    <div className="maya-ple">
      <Helmet>
        <title>Envision Beta</title>
        <meta name="description" content="Envision Beta" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('Your beta access is just a step away')}</h3>
      </div>
      <div className="content free-trials">
        <br />
        {error && <div className="error">{error.message}</div>}
        <br />
        <div className="content role-form">
          <EnvisionForm setCanContinue={setCanContinue} />
          <br />
          <div className="centered">
            <button type="button" className="button" onClick={onSubmit} disabled={!canContinue}>
              {t('Start your beta')}
            </button>
          </div>
          <br />
          <Privacy />
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    loading: state.beta.loading,
    selected: state.beta.selected,
    error: state.beta.error,
  }),
  (dispatch) => ({
    onOccupationSelect: (occupation) => dispatch({ type: BETA_SELECT, data: { using_as: occupation } }),
    onSubmit: () => dispatch({ type: BETA_CREATE_ENVISION }),
  }),
)(withTranslation()(Envision));
