import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

const NotEligible = ({ t }) => {
  return (
    <div className="maya-ple">
      <Helmet>
        <title>Envision Beta</title>
        <meta name="description" content="Envision Beta" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('Envision Beta')}</h3>
      </div>
      <div className="content free-trials">
        <div className="content role-form">
          <br />
          <br />
          <div className="centered">
            <br />
            {t(
              'You already have an active Chaos Envision, beta license. Your license will be valid throughout the beta period. If you have any questions,',
            )}{' '}
            <a target="_blank" rel="noreferrer" href="https://forums.chaos.com/">
              {t('visit our forum')}
            </a>
            .
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NotEligible);
