/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BETA_BLENDER_SELECT, BlenderRenderEngines } from './constants';
import { Industries, Roles } from '../FreeTrial/constants';

const Individual = ({ type, selected, onFormChange, setCanContinue, t }) => {
  const { company, industry, blender_render_engine, blender_render_engine_other } = selected;
  const { name, role, additional_name, additional_role } = industry || {};

  useEffect(() => {
    if (selected.industry && name && role && blender_render_engine) {
      if (
        (name === 'Other' && !additional_name) ||
        (role === 'Other' && !additional_role) ||
        (blender_render_engine === 'Other' && !blender_render_engine_other)
      ) {
        setCanContinue(false);
        return;
      }
      if (type === 'business' && !company) {
        setCanContinue(false);
        return;
      }

      setCanContinue(true);
      return;
    }

    setCanContinue(false);
  }, [selected]);

  return (
    <>
      <p>{t('What is your field of practice?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) =>
              onFormChange({
                industry: {
                  ...industry,
                  name: e.target.value,
                  additional_name: '',
                },
              })
            }
            value={name}
          >
            <option value="">{t('Please select')}</option>
            {Industries.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {name === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your industry')} *</label>
              <input
                type="text"
                required
                defaultValue={additional_name}
                onChange={(e) =>
                  onFormChange({
                    industry: {
                      ...industry,
                      additional_name: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>{t('What is your role?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) =>
              onFormChange({
                industry: {
                  ...industry,
                  role: e.target.value,
                  additional_role: '',
                },
              })
            }
            defaultValue={role}
          >
            <option value="">{t('Please select')}</option>
            {Roles.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {role === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your role')} *</label>
              <input
                type="text"
                required
                value={additional_role}
                onChange={(e) =>
                  onFormChange({
                    industry: {
                      ...industry,
                      additional_role: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <br />
        </>
      )}
      {type === 'business' && (
        <>
          <p>{t('Organization name')}</p>
          <div className="form-field-wrapper">
            <div className="item item-organization">
              <input
                type="text"
                required
                value={company || ''}
                onChange={(e) => onFormChange({ company: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      <br />
      <p>{t('Which render engine do you currently use in Blender?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) => onFormChange({ blender_render_engine: e.target.value })}
            value={blender_render_engine}
          >
            <option value="">{t('Please select')}</option>
            {BlenderRenderEngines.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {blender_render_engine === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your render engine')}</label>
              <input
                type="text"
                required
                defaultValue={blender_render_engine_other}
                onChange={(e) => onFormChange({ blender_render_engine_other: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    selected: state.betaBlender.selected,
    location: state.auth.Location,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: BETA_BLENDER_SELECT, data }),
  }),
)(withTranslation()(Individual));
