import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Loading from '../App/Loading';
import { CustomStepper } from '../FreeTrial/Wiz';
import BetaForm from './betaForm';

const BetaBlender = ({ loading, step, error, t }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <div className="maya-ple">
      <Helmet>
        <title>V-Ray for Blender beta</title>
        <meta name="description" content="V-Ray for Blender beta" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('V-Ray for Blender beta')}</h3>
      </div>
      <div className="content free-trials">
        <div
          style={{ fontSize: 10, color: '#3D7800', backgroundColor: '#D5E7C0', borderRadius: 4, padding: '5px 10px' }}
        >
          Currently, we only support Windows and Blender 4.2. Using a different OS or Blender version? Complete{' '}
          <a
            href="https://chaos.surveysparrow.com/s/v-ray-for-blender---beta/tt-p9cj4"
            target="_blank"
            rel="noreferrer"
          >
            this short survey
          </a>{' '}
          to help us decide what to support next.
        </div>
        <CustomStepper steps={Array(3).fill()} step={step} />
        <br />
        {error && <div className="error">{error.message}</div>}
        <br />
        <div className="content role-form">
          <BetaForm />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  loading: state.betaBlender.loading,
  step: state.betaBlender.step,
  error: state.betaBlender.error,
}))(withTranslation()(BetaBlender));
