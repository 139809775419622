import React from 'react';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { withTranslation } from 'react-i18next';

const THEME = createTheme({
  typography: {
    fontFamily: `"Poppins", sans-serif`,
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
  },
});

const Connector = withStyles({
  active: {
    '& $line': {
      border: '1px solid #0DB5FF',
    },
  },
  completed: {
    '& $line': {
      border: '1px solid #0DB5FF',
    },
  },
  line: {
    border: '1px solid #ABABAB',
    height: 1,
    margin: '0 22px 0 12px',
  },
})(StepConnector);

const styles = {
  wrapper: {
    flexDirection: 'column',
    margin: '0 auto',
    maxWidth: '1092px',
  },
  stepLabel: {
    fontSize: '17px',
    fontWeight: '700 !important',
    marginLeft: '12px',
    textAlign: 'left',
    '& .progress-step-subtitle': {
      fontSize: '13px',
      fontWeight: '400',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#1C1C1C',
      maxWidth: 130,
    },
  },
  actionsWrapper: {
    height: '72px',
    bottom: 0,
    left: 0,
    borderTop: '1px solid #EDEDED',
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  stepper: {
    padding: '24px 0',
    maxWidth: 450,
    width: '100%',
    margin: '0 auto',
  },
};

const Wiz = ({ steps, progressBar, classes }) => {
  const { step } = progressBar;

  return (
    <MuiThemeProvider theme={THEME}>
      <Grid container className={classes.wrapper}>
        {steps.map(({ Component }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>{step === index && <Component />}</div>
        ))}
      </Grid>
    </MuiThemeProvider>
  );
};

export const CustomStepper = withStyles(styles)(({ steps, step, classes }) => {
  const getIcon = (index) => {
    const isStepActive = step === index;

    const stepIndex = index + 1;
    if (isStepActive) {
      return <img alt={`step-${stepIndex}-active`} src={`/images/stepper/step-${stepIndex}-active.svg`} />;
    }

    const isStepInactive = step < index;
    if (isStepInactive) {
      return <img alt={`step-${stepIndex}-inactive`} src={`/images/stepper/step-${stepIndex}-inactive.svg`} />;
    }

    return <img alt={`step-${stepIndex}-completed`} src="/images/stepper/step-completed.svg" />;
  };

  return (
    <Stepper className={classes.stepper} activeStep={step} connector={<Connector />}>
      {steps.map((_, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key, react/jsx-props-no-spreading
          <Step key={index}>
            <StepLabel classes={{ label: classes.stepLabel }} icon={getIcon(index)} />
          </Step>
        );
      })}
    </Stepper>
  );
});

export default withStyles(styles)(withTranslation()(Wiz));
