import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { navigate } from 'redux-saga-first-router';

import { FREE_TRIAL_IMPACT_ORDER, FREE_TRIAL_SEND_IS_IMPACT_USER } from './constants';

const EnscapeImpact = ({ routing, placeImpactOrder, navigateRole, t }) => {
  return (
    <div className="content impact">
      <p className="info">
        {t(
          'Enscape Impact is a brand-new add-on designed to seamlessly integrate real-time rendering with building performance metrics.',
        )}
        <br />
        <br />
        {t('It empowers your workflow, making your ideas sustainable from the start.')}
      </p>
      <p className="perks">
        <div>
          <img src="/images/trial/enscape-impact/energy-use.svg" alt="trial" />
          <span>{t('Fast energy modelling simulation')}</span>
        </div>
        <div>
          <img src="/images/trial/enscape-impact/integrated-workflow.svg" alt="trial" />
          <span>{t('Data-driven decision making')}</span>
        </div>
        <div>
          <img src="/images/trial/enscape-impact/data-entry.svg" alt="trial" />
          <span>{t('Integrated workflow')}</span>
        </div>
        <div>
          <img src="/images/trial/enscape-impact/dials-panel.svg" alt="trial" />
          <span>{t('Increased productivity')}</span>
        </div>
      </p>
      <p>
        {t(
          'Fully compatible with major CAD and BIM software tools, such as Revit, SketchUp, Archicad, Rhino, and Vectorworks. ',
        )}
      </p>
      <p>{t('Currently available for Windows users only.')}</p>
      <p className="actions">
        <button type="button" className="button" onClick={placeImpactOrder}>
          {t('Try Enscape Impact')}
        </button>
        <br />
        <br />
        <a className="reject" onClick={() => navigateRole(routing.params)}>
          {t('No, thanks')}
        </a>
      </p>
    </div>
  );
};

export default connect(
  (state) => ({
    routing: state.routing,
  }),
  (dispatch) => ({
    placeImpactOrder: () => {
      dispatch({ type: FREE_TRIAL_IMPACT_ORDER });
    },
    navigateRole: (routingParams) => {
      dispatch(navigate('FREE_TRIAL', { ...routingParams, step: 'role' }));
      dispatch({ type: FREE_TRIAL_SEND_IS_IMPACT_USER, isImpactUser: false });
    },
  }),
)(withTranslation()(EnscapeImpact));
