export const Industries = [
  {
    label: 'Animation',
    value: 'Animation',
  },
  {
    label: 'Automotive',
    value: 'Automotive',
  },
  {
    label: 'Advertising',
    value: 'Advertising',
  },
  {
    label: 'Education / Academia',
    value: 'Education / Academia',
  },
  {
    label: 'Gaming',
    value: 'Gaming',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const Roles = [
  {
    label: 'Designer - Product',
    value: 'Designer - Product',
  },
  {
    label: 'Educator / Professor / Teacher',
    value: 'Educator',
  },
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Visualization Specialist / 3D Artist',
    value: '3D Artist',
  },
  {
    label: 'Technical Director',
    value: 'Technical Director',
  },
  {
    label: 'CG / VFX Supervisor',
    value: 'VFX Supervisor',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const EduFields = [
  { label: 'VFX', value: 'VFX' },
  { label: 'Animation', value: 'Animation' },
  { label: 'Product Design', value: 'Product Design' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'Other', value: 'Other' },
];

export const RenewalReasons = [
  { label: 'I didn’t have time to test it', value: 'I didn’t have time to test it' },
  { label: 'I want to keep learning', value: 'I want to keep learning' },
  {
    label: 'I have a personal/university project to complete',
    value: 'I have a personal/university project to complete',
  },
  { label: 'I am attending a rendering class/course', value: 'I am attending a rendering class/course' },
  { label: 'Other', value: 'Other' },
];

export const UseFrequency = [
  { label: 'Every day', value: 'Every day' },
  { label: 'Every week', value: 'Every week' },
  { label: 'Once a month', value: 'Once a month' },
  { label: 'Occasionally to complete a project/class', value: 'Occasionally to complete a project/class' },
];

export const PlannedUseDuration = [
  { label: 'One month', value: 'One month' },
  { label: 'Three months', value: 'Three months' },
  { label: 'Six months', value: 'Six months' },
  { label: 'As long as I need to learn V-Ray', value: 'As long as I need to learn V-Ray' },
  { label: 'Not sure, just testing for now', value: 'Not sure, just testing for now' },
];
